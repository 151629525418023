import HomeIcon from "@mui/icons-material/Home";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import InfoIcon from "@mui/icons-material/Info";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import LanguageIcon from "@mui/icons-material/Language";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PersonIcon from "@mui/icons-material/Person";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks/store.hooks";
import { logout } from "../../../store/models/auth/auth.thunk";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton,
  IconButton,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import home from "../../../assets/icons/menu/home.svg";
import bonuses from "../../../assets/icons/menu/bonuses.svg";
import info from "../../../assets/icons/menu/info.svg";
import partners from "../../../assets/icons/menu/partners.svg";
import support from "../../../assets/icons/menu/support.svg";
import wallet from "../../../assets/icons/menu/wallet.svg";
import game from "../../../assets/icons/menu/game.svg";
import personal_account from "../../../assets/icons/menu/personal_account.svg";
import CloseIcon from "@mui/icons-material/Close";
interface Menuappbarlist {
  auth: boolean | null;
  onClose: () => void;
}
const menuItemsAuth = [
  {
    link: "/",
    text: "Главная",
    i18next: "main",
    icon: <img src={home} alt="main" />,
  },
  {
    link: "/profile",
    text: "Личный кабинет",
    i18next: "profile",
    icon: <img src={personal_account} alt="personal_account" />,
  },
  {
    link: "/profile/payments",
    text: "Касса",
    i18next: "deposit",
    icon: <img src={wallet} alt="wallet" />,
  },
  {
    link: "/lobby",
    text: "Игровая зала",
    i18next: "lobby",
    icon: <img src={game} alt="game" />,
  },
  {
    link: "/bonuses",
    text: "Бонусы",
    i18next: "bonuses",
    icon: <img src={bonuses} alt="bonuses" />,
  },
];
const menuItems = [
  {
    link: "/",
    text: "Главная",
    i18next: "main",
    icon: <img src={home} alt="Main" />,
  },
  {
    link: "/profile/payments",
    text: "Касса",
    i18next: "deposit",
    icon: <img src={wallet} alt="wallet" />,
  },
  {
    link: "/lobby",
    text: "Игровая зала",
    i18next: "lobby",
    icon: <img src={game} alt="game" />,
  },
  {
    link: "/bonuses",
    text: "Бонусы",
    i18next: "bonuses",
    icon: <img src={bonuses} alt="bonuses" />,
  },
];
const secondMenuItemsAuth = [
  {
    link: "/partner",
    text: "Партнерам",
    i18next: "partner",
    icon: <img src={partners} alt="partners" />,
  },
  {
    link: "/policy",
    text: "Информация",
    i18next: "information",
    icon: <img src={info} alt="info" />,
  },
  {
    link: "/policy",
    text: "Поддержка",
    i18next: "support",
    icon: <img src={support} alt="support" />,
  },
];

const secondMenuItems = [
  {
    link: "/policy",
    text: "Информация",
    i18next: "information",
    icon: <img src={info} alt="info" />,
  },
  {
    link: "/policy",
    text: "Поддержка",
    i18next: "support",
    icon: <img src={support} alt="support" />,
  },
];

const Menuappbarlist: React.FC<Menuappbarlist> = ({ auth, onClose }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleCloseNavMenu = (link?: string) => {
    onClose();
    //@ts-ignore
    navigate(link);
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",

          padding: "10px 20px",
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            padding: "0",
            borderRadius: "4px !important",
          }}
        >
          <CloseIcon sx={{ fontSize: "25px" }} />
        </IconButton>
      </Box>
      {auth && (
        <Box
          className="primary-text-color"
          sx={{
            margin: "8px 24px",
          }}
        >
          {menuItemsAuth.map((item) => {
            return (
              <MenuItem
                key={item.text}
                onClick={() => handleCloseNavMenu(item.link)}
                className="primary-text-color"
                sx={{
                  padding: "8px 16px",
                  borderRadius: "var(--primary-border-radius)",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                {/* <ListItemText>{item.text}</ListItemText> */}
                <ListItemText className="primary-text-color">
                  {t(`main.menuItems.${item.i18next}`)}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Box>
      )}

      {!auth && (
        <Box
          className="primary-text-color"
          sx={{
            margin: "8px 24px",
          }}
        >
          {menuItems.map((item) => {
            return (
              <MenuItem
                className="primary-text-color"
                onClick={() => handleCloseNavMenu(item.link)}
                key={item.text}
                sx={{
                  padding: "8px 16px",
                  borderRadius: "var(--primary-border-radius)",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText className="primary-text-color">
                  {t(`main.menuItems.${item.i18next}`)}
                </ListItemText>
              </MenuItem>
            );
          })}
        </Box>
      )}
      <Skeleton
        animation={false}
        color="inherit"
        height={2}
        style={{ margin: "2rem 0" }}
      />
      <Box
        className="primary-text-color"
        sx={{
          margin: "8px 24px",
        }}
      >
        {auth &&
          secondMenuItemsAuth.map((item) => {
            return (
              <MenuItem
                className="primary-text-color"
                onClick={() => handleCloseNavMenu(item.link)}
                key={item.text}
                sx={{
                  padding: "8px 16px",
                  borderRadius: "var(--primary-border-radius)",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText className="primary-text-color">
                  {t(`main.menuItems.${item.i18next}`)}
                </ListItemText>
              </MenuItem>
            );
          })}
        {!auth &&
          secondMenuItems.map((item) => {
            return (
              <MenuItem
                className="primary-text-color"
                onClick={() => handleCloseNavMenu(item.link)}
                key={item.text}
                sx={{
                  padding: "8px 16px",
                  borderRadius: "var(--primary-border-radius)",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText className="primary-text-color">
                  {t(`main.menuItems.${item.i18next}`)}
                </ListItemText>
              </MenuItem>
            );
          })}
      </Box>
    </>
  );
};

export default Menuappbarlist;
