export enum AuthAction {
  SET_AUTH = "setAuth",
  LOGIN = "login",
  LOGIN_WITH_GOOGLE = "loginWithGoogle",
  LOGIN_WITH_YANDEX = "loginWithYandex",
  REGISTRATION = "registration",
  LOGOUT = "logout",
  ACTIVATION_EMAIL = "activationEmail",
  RESET_LINK = "resetLink",
  RESET_PASSWORD = "resetPassword",
  VERIFY_AUTHORIZATION = "verifyAuthorization",
  SET_VERIFYED = "setVerifyed",
  SET_AUTHORIZATION_MODAL = "setAuthorizationModal",
  SET_REGISTRATION_MODAL = "setRegistrationModal",
  SET_PASSWORD_MODAL = "setPasswordModal",
  SET_INFO_MODAL = "setInfoModal",
  SET_REDIRECT = "setRedirect",
}
