import React from "react";
import { Box } from "@mui/material";
import { ProfileHeader } from "../ProfileHeader/ProfileHeader";
import "./ProfileContentContainer.css";

interface ProfileContentContainerProps {
  children: React.ReactNode;
  links: ILinkItem[];
}
interface ILinkItem {
  link: string;
  text: string;
}
export const ProfileContentContainer = ({
  children,
  links,
}: ProfileContentContainerProps) => {
  return (
    <Box className="profile-content">
      <ProfileHeader links={links} />
      <Box className="profile-content__container ">{children}</Box>
    </Box>
  );
};
