import { Box, Container, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { Link } from "react-router-dom";
import { fetchMyActivatedBonuses } from "../../../../store/models/bonuses/bonuses.thunk";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import "../ActiveBonus/ProfileActiveBonuses.css";
import { useMediaQuery } from "react-responsive";
import { ActiveBonusItem } from "../../../Bonuses/ActiveBonusItem/active.bonus.item";
import { inactiveBonusesListSelector } from "./HistoryBonus.selector";
import { Loader } from "../../../loader/loader";

const ProfileHistoryBonuses = () => {
  const dispatch = useAppDispatch();
  const locator = useLocation();
  const [loading, setLoading] = useState(true);
  const { inactivatedBonuses } = useAppSelector(inactiveBonusesListSelector);
  const isMobile = useMediaQuery({ maxWidth: 999 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fetchMyActivatedBonuses({ mobile: isMobile }));
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: "..", text: "АКТИВНЫЕ" },
        { link: "../available", text: "ДОСТУПНЫЕ" },
        { link: "", text: "ИСТОРИЯ" },
      ]}
    >
      <Box className="active-bonuses">
        <Box className="active-bonuses__container">
          {loading ? (
            <Loader />
          ) : inactivatedBonuses.length > 0 ? (
            <Box className="active-bonuses__bonuses-list">
              {inactivatedBonuses.map((bonus, index) => (
                <ActiveBonusItem
                  type={bonus.bonus_type}
                  key={bonus.id}
                  {...bonus}
                  sub_img={
                    isMobile
                      ? bonus.bonus.images.mobile.sub_image
                      : bonus.bonus.images.desktop.sub_image
                  }
                  customClassName={"active-bonuses__item"}
                />
              ))}
            </Box>
          ) : (
            <Box
              className="no-bonuses"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h6" color="textPrimary">
                У вас еще нет истории бонусов
              </Typography>
              <Link to="/bonuses" className="no-bonuses__link">
                Перейти к бонусам
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </ProfileContentContainer>
  );
};

export default ProfileHistoryBonuses;
