import { memo, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Loader } from "../components/loader/loader";
import { useAppDispatch, useAppSelector } from "../store/hooks/store.hooks";
import { setAuthorizationModal } from "../store/models/auth/auth.slice";

interface PropType {
  component: React.FC;
}

const AuthRoute: React.FC<PropType> = memo(({ component: Component }) => {
  const [auth, isVerifyed] = useAppSelector((state) => [
    state.authorization.auth,
    state.authorization.isVerifyed,
  ]);
  const dispatch = useAppDispatch();

  if (isVerifyed) {
    if (!auth) return <Component />;

    //  dispatch(setAuthorizationModal(true));
    return <Navigate to="/" />;
  } else {
    return <Loader />;
  }
});

export default AuthRoute;
