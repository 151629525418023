import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import { changeWallet } from "../../store/models/profile/profile.thunk";
import "./Wallets.css";
import { WalletsSelector } from "./Wallets.selector";

export const Wallets = () => {
  const [loading, setLoading] = useState(false);
  const { wallets } = useAppSelector(WalletsSelector);
  const dispatch = useAppDispatch();
  // debugger;
  const handleChange = async (e: any) => {
    setLoading(true);
    // debugger;
    if (loading) return;
    const res = await dispatch(
      changeWallet({ currency: JSON.parse(e.target.value) })
    );
    // console.log("------", e.target.value);
    setLoading(false);
  };

  return (
    <Container
      maxWidth={false}
      disableGutters
      className="wallets-container container"
    >
      <Typography className="wallets-tittle" component="p">
        ВЫРОБ ИГРОВОГО КОШЕЛЬКА
      </Typography>
      <FormGroup style={{ flexDirection: "row" }}>
        {wallets?.map((w: any, i: any) => {
          const summaryBalance =
            +w.balance.main_balance +
              +w.balance.bonus_balance +
              +w.balance.reserved_balance ||
            (0)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          return (
            <FormControlLabel
              key={i}
              // onClick={handleChangeWallet}
              onChange={handleChange}
              className="wallets-form-control"
              value={JSON.stringify(w.currency)}
              control={
                <Checkbox
                  disabled={loading}
                  checked={w.active}
                  sx={{
                    color: "rgba(19, 26, 47, 1)",
                    "&.Mui-checked": {
                      color: "rgb(223, 0, 67)",
                    },
                  }}
                />
              }
              label={
                <Box>
                  <Typography className="wallets-currency" component="p">
                    {w.currency.name}
                  </Typography>
                  <Typography className="wallets-amount" component="p">
                    {summaryBalance}
                  </Typography>
                </Box>
              }
              labelPlacement="end"
            />
          );
        })}
      </FormGroup>
      {/* <FormControl style={{ flexDirection: "row" }}>
        {wallets?.map((w, i) => {
          const summaryBalance = (
            +w.balance.main_balance +
            +w.balance.bonus_balance +
            +w.balance.reserved_balance
          )
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
          return (
            <FormControlLabel
              key={i}
              onClick={handleChangeWallet}
              className="wallets-form-control"
              value={w.id}
              control={
                <Checkbox
                  disabled={loading}
                  checked={w.active}
                  sx={{
                    color: "rgba(19, 26, 47, 1)",
                    "&.Mui-checked": {
                      color: "rgb(223, 0, 67)",
                    },
                  }}
                />
              }
              label={
                <Box value={w.id} onClick={handleChangeWallet}>
                  <Typography className="wallets-currency" component="p">
                    {w.currency}
                  </Typography>
                  <Typography className="wallets-amount" component="p">
                    {summaryBalance}
                  </Typography>
                </Box>
              }
              labelPlacement="end"
            />
          );
        })}
      </FormControl> */}
      {/* <Box>DEPOSIT</Box> */}
    </Container>
  );
};
