import React from "react";
import { FooterPayments } from "./FooterPayments/FooterPayments";
import { FooterContacts } from "./FooterContacts/FooterContacts";
import { FooterLanguage } from "./FooterLanguage/FooterLanguage";
import { Box, Container } from "@mui/material";
import { FooterNav } from "./FooterNav/FooterNav";
import { FooterResponsiveGaming } from "./FooterResponsiveGaming/FooterResponsiveGaming";
import { FooterAllReserved } from "./FooterAllReserved/FooterAllReserved";
import "./Footer.css";
import { useMediaQuery } from "react-responsive";

export const Footer = () => {
  const isBig = useMediaQuery({ minWidth: 1599 });
  return (
    <div className="footer">
      <Container className="container" maxWidth={false} disableGutters>
        <FooterPayments slider={false} />
        <Box className="footer__flex-container">
          <FooterContacts />

          <FooterNav />
        </Box>
        {/* <FooterLanguage /> */}
        <FooterResponsiveGaming />
      </Container>
      <FooterAllReserved />
    </div>
  );
};
