import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import emailSrc from "../../../assets/contacts/email.svg";
import chat from "../../../assets/contacts/chat.svg";
import "./FooterContacts.css";
import { useTranslation } from "react-i18next";
import {
  LiveChatWidget,
  EventHandlerPayload,
  useWidgetIsReady,
  useWidgetState,
} from "@livechat/widget-react";
import { useAppSelector } from "../../../store/hooks/store.hooks";
import { jivoSelector } from "../../jivo/jivo.selector";
import { IJivoSelector } from "../../jivo/typing/jivo.interface";

export const FooterContacts = () => {
  const { t } = useTranslation();
  const { name, email, phone, id }: IJivoSelector =
    useAppSelector(jivoSelector);
  const [chatState, setChatState] = useState<
    "hidden" | "maximized" | "minimized"
  >("hidden");

  const widgetState = useWidgetState();
  const isWidgetReady = useWidgetIsReady();

  useEffect(() => {
    if (widgetState) {
      setChatState(widgetState.visibility);
    }
  }, [widgetState]);

  function handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
    //console.log("LiveChatWidget.onNewEvent", event);
  }

  return (
    <Box className="contacts">
      <h3 className="contacts__heading">{t("footer.support")}</h3>
      <Box className="contacts__links-container">
        <Box className="contacts__link">
          <img src={emailSrc} alt="Email: support@enomo.casino" />
          <a className="contacts__link" href="mailto:support@enomo.casino">
            support@enomo.casino
          </a>
        </Box>
        {isWidgetReady && (
          <Box className="contacts__link">
            <img src={chat} alt="live chat" style={{ padding: "4.5px" }} />

            <Box
              component={"a"}
              onClick={() => {
                setChatState("maximized");
              }}
              className="contacts__link"
            >
              Онлайн чат поддержки
            </Box>
          </Box>
        )}

        <LiveChatWidget
          license="18252378"
          visibility={chatState}
          customerName={name || id || undefined}
          customerEmail={email || undefined}
          onNewEvent={handleNewEvent}
        />
        {/* <span>{widgetState?.availability}</span>
        <span>{widgetState?.visibility}</span> */}
        {/* <ChatLive state={chatState} /> */}
      </Box>
    </Box>
  );
};
