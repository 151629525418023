import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { Wallets } from "../../../wallets/Wallets";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import "./ProfileDeposit.css";

import { PaymentCard } from "../PaymentCard/PaymentCard";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import {
  createDepositPayment,
  getPaymentMethods,
  validatePromocode,
  verifyDeposits,
} from "../../../../store/models/payment/payment.thunk";
import DepositModal from "../PaymentModal/DepositModal";
import { IPaymentDialogState } from "../typing";
import { profileDepositSelector } from "./ProfileDeposit.selector";
import { IMethod } from "../../../../store/models/payment/typing/payment.interface";

export const ProfileDeposit = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState<null | number>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [blocked, setBlocked] = useState<boolean>(false);
  const [paymentInfo, setPaymentInfo] = useState<{
    amount: number | null;
    method_id: string | null;
    promocode: string | null;
  }>({
    amount: null,
    method_id: null,
    promocode: null,
  });
  const [continuePayment, setContinuePayment] = useState<boolean>(false);
  const { paymentMethods } = useAppSelector(profileDepositSelector);

  const [dialog, setDialog] = useState<IPaymentDialogState>({
    modal: false,
    method_type: "card",
    method_id: "0",
    currency: "",
    image: "",
    min: 0,
    max: 0,
  });
  const dispatch = useAppDispatch();

  const depositHandler = async (
    amount: number | null,
    method_id: string,
    promocode: string | null
  ) => {
    // debugger;

    if (amount && method_id) {
      setPaymentInfo({
        amount,
        method_id,
        promocode,
      });

      setLoading(true);
      sessionStorage.setItem("enmtdin", method_id);
      if (promocode) {
        // debugger;
        const isValid: any = await dispatch(validatePromocode({ promocode }));

        if (isValid?.payload?.result?.bonus) {
          await dispatch(
            createDepositPayment({
              amount,
              method_id,
              promocode,
            })
          );
        } else {
          setContinuePayment(true);
        }
      } else {
        await dispatch(
          createDepositPayment({
            amount,
            method_id,
          })
        );
      }
    }
    setLoading(false);
  };
  const handleOpen = (
    method_id: string,
    image: string,
    currency: string,
    min: number,
    max: number,
    method_type: IPaymentDialogState["method_type"]
  ) => {
    // debugger;
    setDialog({
      modal: true,
      method_id,
      image,
      currency,
      min,
      max,
      method_type,
    });
  };
  const continuePaymentHandler = async () => {
    setLoading(true);
    setContinuePayment(false);

    if (paymentInfo.amount && paymentInfo.method_id) {
      await dispatch(
        createDepositPayment({
          amount: paymentInfo.amount,
          method_id: paymentInfo.method_id,
        })
      );
    }

    setLoading(false);
  };
  const closeHandler = () => {
    setDialog({
      modal: false,
      method_id: "0",
      image: "",
      currency: "",
      min: 0,
      max: 0,
      method_type: "card",
    });
  };

  useEffect(() => {
    dispatch(getPaymentMethods());
  }, []);

  useEffect(() => {
    const verify = async () => {
      const response = await dispatch(verifyDeposits());
      if (response.payload?.error) {
        setLoading(false);
        return;
      }
      if (response.type === "verifyDeposits/fulfilled") {
        setLoading(false);
        setBlocked(response.payload);
      }
    };
    verify();
  }, []);
  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: ".", text: t("main.profile.links.deposit") },
        { link: "withdrawal", text: t("main.profile.links.withdrawal") },
        {
          link: "payment-history",
          text: t("main.profile.links.history"),
        },
      ]}
    >
      <Box className="profile-deposit">
        {blocked && (
          <Box sx={{ width: "100%" }}>
            <Collapse in={true}>
              <Alert
                style={{
                  alignItems: "center",
                  borderTopRightRadius: "14px",
                  borderTopLeftRadius: "14px",
                  borderBottomRightRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
                severity="error"
              >
                Пополнение игрового счета временно ограничено. Для возобновления
                возможности внести депозит обратитесь в Службу Поддержки
              </Alert>
            </Collapse>
          </Box>
        )}

        <Dialog
          open={continuePayment}
          onClose={() => setContinuePayment(false)}
          className="game-dialog"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("main.profile.payments.deposit.confirmation")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={loading}
              className={"Button" + ` Button-secondary`}
              onClick={continuePaymentHandler}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("main.profile.payments.deposit.continue")}
            </Button>
          </DialogActions>
        </Dialog>

        <Box className="profile-deposit__container">
          {blocked && <Box className="profile-deposit__restrictions" />}
          <Typography className="profile-deposit__title" component="h6">
            {t("main.profile.payments.deposittitle")}
          </Typography>
          <Grid container className="profile-deposit__grid-container">
            {paymentMethods.map((pay: IMethod, i) => {
              return (
                <Grid item key={i}>
                  <PaymentCard
                    image={pay.image.src}
                    id={pay.id}
                    text={pay.name}
                    active={active}
                    buttonText={t("buttons.shorttopup")}
                    onHover={() => {
                      setActive(pay.id);
                    }}
                    onLeave={() => setActive(null)}
                    onClick={() => {
                      handleOpen(
                        String(pay.id),
                        pay.image.src,
                        pay.currency,
                        pay.min,
                        pay.max,
                        pay.method_type
                      );
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Wallets />
      {dialog.modal && !blocked && (
        <DepositModal
          open={dialog.modal}
          // payment_system={dialog.payment_system}
          method_id={dialog.method_id}
          image={dialog.image}
          paymentCurrency={dialog.currency}
          min={dialog.min}
          max={dialog.max}
          closeHandler={closeHandler}
          depositHandler={depositHandler}
          loading={loading}
        />
      )}
    </ProfileContentContainer>
  );
};
