import React from "react";
import { Box, TextField, InputAdornment, Typography } from "@mui/material";
import { IInputProps } from "./typing/types";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./input.css";

export const ProfileInput = ({
  error,
  touched,
  handleChange,
  handleBlur,
  value,
  name,
  label,
  confirmed,
  disabled,
  withoutPlaceholder,
}: IInputProps) => {
  if (withoutPlaceholder) {
    return (
      <Box className="profile-input">
        <label htmlFor={name} className="profile-input__label">
          {label}
        </label>
        <TextField
          // error={Boolean(error && touched)}
          size="small"
          margin="normal"
          fullWidth
          type="text"
          id={name}
          name={name}
          autoComplete="off"
          onChange={handleChange}
          onBlur={handleBlur}
          value={value}
          disabled={disabled}
          InputProps={
            error && touched
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <ErrorOutlineOutlinedIcon
                        style={{
                          color: "rgba(223, 0, 67, 1)",
                          fontWeight: 700,
                        }}
                      />
                    </InputAdornment>
                  ),
                }
              : confirmed
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <CheckCircleOutlineIcon
                        style={{
                          color: "rgba(128, 223, 0, 1)",
                          fontWeight: 700,
                        }}
                      />
                    </InputAdornment>
                  ),
                }
              : undefined
          }
        />
        {error && touched ? (
          <Typography
            className="Typography-error"
            component="p"
            variant="body2"
          >
            {error}
          </Typography>
        ) : null}
      </Box>
    );
  }
  return (
    <Box className="profile-input">
      <label htmlFor={name} className="profile-input__label">
        {label}
      </label>
      <TextField
        error={Boolean(error && touched)}
        size="small"
        margin="normal"
        fullWidth
        type="text"
        id={name}
        placeholder={label}
        name={name}
        autoComplete="off"
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
        disabled={disabled}
        InputProps={
          error && touched
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <ErrorOutlineOutlinedIcon
                      style={{
                        color: "rgba(223, 0, 67, 1)",
                        fontWeight: 700,
                      }}
                    />
                  </InputAdornment>
                ),
              }
            : confirmed
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <CheckCircleOutlineIcon
                      style={{
                        color: "rgba(128, 223, 0, 1)",
                        fontWeight: 700,
                      }}
                    />
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {error && touched ? (
        <Typography className="Typography-error" component="p" variant="body2">
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};
