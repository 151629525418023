import React, { useRef } from "react";
import "./loader.css";
import enomo from "../../assets/icons/enomo.svg";

type ILoaderProp = {
  secondary?: boolean;
};

export const Loader = ({ secondary }: ILoaderProp) => {
  const ref = useRef<SVGPathElement | null>(null);
  //@ts-ignore
  // console.log(ref.current?.getTotalLength());
  return (
    <div className={`loading ${secondary ? "secondary" : ""}`}>
      <img src={enomo} alt="Enomo loader" />
    </div>
  );
};
