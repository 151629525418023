import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { SlotCard } from "../SlotCard/SlotCard";
import { IStoreSlot } from "../../../store/models/slots/typing/slot.interface";
import MButton from "../../Button/Button";
import "./SlotCategories.css";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hooks/store.hooks";
import {
  setAnimalsGames,
  setBooksGames,
  setBuyFSGames,
  setFruitsGames,
  setNewGames,
  setPopularGames,
  setRecommendedGames,
  setSevensGames,
  setSlotGames,
} from "../../../store/models/slots/slot.slice";
interface ISlotCategoriesProps {
  isMain: boolean;
  heading: string;
  games: IStoreSlot[];
  active: number | null;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  setFilter: (title: string) => void;
  action?: string;
}
export const SlotCategories = ({
  isMain,
  heading,
  games,
  active,
  action,
  setActive,
  setFilter,
}: ISlotCategoriesProps) => {
  const [more, setMore] = useState<boolean | null>(false);
  const [count, setCount] = useState<number>(10);
  const navigator = useNavigate();
  const dispatch = useAppDispatch();
  const setFilterHandler = (title: string) => {
    setFilter(title);
  };
  useEffect(() => {
    if (games.length > count) {
      setMore(true);
    }
    if (games.length <= count) {
      setMore(false);
    }
  }, [games, count]);
  const moreHandler = () => {
    if (isMain && action) {
      if (action === "filterByPopularity" || action === "Популярные") {
        console.log("action", action);
        dispatch(setPopularGames());
        isMain && navigator("/lobby");
      }
      if (action === "filterByNew" || action === "Новинки") {
        console.log("action", action);
        dispatch(setNewGames());
        isMain && navigator("/lobby");
      }
      if (action === "filterBySlots" || action === "Все слоты") {
        console.log("action", action);
        dispatch(setSlotGames());
        isMain && navigator("/lobby");
      }
    } else {
      setCount((count) => count + 15);
    }
  };
  return (
    <Box
      className={`slot-category ${isMain ? "slot-main" : "slot-lobby"}`}
      onClick={() => {
        setActive(null);
      }}
      sx={{ display: games.length > 0 ? "block" : "none" }}
    >
      <Box className="slot-category__header">
        <Typography
          className="slot-category__heading"
          variant="subtitle1"
          component="h2"
        >
          {heading}
        </Typography>
      </Box>

      <Grid
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {games.map((game, index) => {
          if (index <= count) {
            return (
              <SlotCard
                key={`${heading}_${game.id}`}
                cardId={game.id}
                src={game.image_url}
                title={game.title}
                url={game.url}
                active={active}
                setActive={setActive}
              />
            );
          }
          return null;
        })}
      </Grid>
      {more && (
        <div className="slots-categories__button-container">
          <MButton
            className="slots-categories__button primary-hover"
            text={"еще игры"}
            styling={"secondary"}
            onClick={moreHandler}
          />
        </div>
      )}
    </Box>
  );
};
