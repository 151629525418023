import { RootState } from "../../../store/rootStore";
import { createSelector } from "reselect";
import { IPersonalInfoSelector } from "./typing/PersonalInfo.interface";

const personalInfo = (state: RootState) => ({
  user: state.user,
  auth: state.authorization.auth,
  isVerifyed: state.authorization.isVerifyed,
});

export const PersonalInfoSelector = createSelector(
  personalInfo,
  ({ user: { verification, verificationStage } }) => {
    return {
      verification: JSON.parse(verification),
      verificationStage,
    } as IPersonalInfoSelector;
  }
);
