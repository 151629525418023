import React, { useEffect, useState } from "react";
import { Box, Button, IconButton, TextField } from "@mui/material";
import "./SlotFilters.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../store/hooks/store.hooks";
import { slotsFilterSelector } from "./SlotsFilters.selector";
import {
  clearFilter,
  findGame,
  searchProvider,
  setAnimalsGames,
  setBooksGames,
  setBuyFSGames,
  setFruitsGames,
  setNewGames,
  setPopularGames,
  setRecommendedGames,
  setSevensGames,
  setSlotGames,
} from "../../../store/models/slots/slot.slice";
import { SearchOutlined } from "@mui/icons-material";
import { ProviderSelect } from "../ProviderSelect/providerSelect";
import { useNavigate } from "react-router-dom";
interface ISlotFiltersProp {
  isMain: boolean;
  filter?: string | null;
}
export const SlotFilters = ({ isMain, filter }: ISlotFiltersProp) => {
  // const [active, setActive] = useState(false);
  const [gameName, setGameName] = useState("");
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const { filters, providers, isFiltered } =
    useAppSelector(slotsFilterSelector);
  const [search, setSearch] = useState(false);
  const applyFilter = (action: string) => {
    if (action === "filterByPopularity" || action === "Популярные") {
      console.log("action", action);
      dispatch(setPopularGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByNew" || action === "Новинки") {
      console.log("action", action);
      dispatch(setNewGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByAnimals" || action === "Животные") {
      console.log("action", action);
      dispatch(setAnimalsGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByBooks" || action === "Книги") {
      console.log("action", action);
      dispatch(setBooksGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByBuyFS" || action === "Купи спины") {
      console.log("action", action);
      dispatch(setBuyFSGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByFruits" || action === "Фрукты") {
      console.log("action", action);
      dispatch(setFruitsGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterBySevens" || action === "777") {
      console.log("action", action);
      dispatch(setSevensGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByRecommended" || action === "Рекомендуемые") {
      console.log("action", action);
      dispatch(setRecommendedGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterBySlots" || action === "Все слоты") {
      console.log("action", action);
      dispatch(setSlotGames());
      isMain && navigator("/lobby");
    }
  };

  useEffect(() => {
    filter && applyFilter(filter);
  }, [filter]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (gameName.trim()) {
        dispatch(findGame(gameName));
      }
    }, 700);

    return () => clearTimeout(delayDebounceFn);
  }, [gameName]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;

    setGameName(currentValue);
    if (currentValue.trim()) {
      // dispatch(findGame(currentValue));
    } else {
      clearFiltersHandler();
    }
  };
  const handleProviderChange = (providerTitle: string) => {
    clearFiltersHandler();
    navigator("/lobby");
    dispatch(searchProvider(providerTitle));
  };

  const clearFiltersHandler = () => {
    dispatch(clearFilter());
    setGameName("");
  };

  return (
    <Box className="slots-filter">
      {isFiltered && (
        <Box className="slots-filter__clear primary-text-color">
          <Button
            type="button"
            className="secondary-hover primary-text-color"
            onClick={() => {
              clearFiltersHandler();
            }}
            sx={{
              "&:hover": {
                backgroundColor: "var(--primary-background-color)",
              },
            }}
          >
            Сбросить
          </Button>
        </Box>
      )}
      <TextField
        size="small"
        className={`slots-filter__search primary-text-color ${
          search ? "active" : ""
        }`}
        type="name"
        value={gameName}
        placeholder={`Поиск игры`}
        onChange={handleSearchChange}
        sx={{
          height: "100%",
          border: "none",
        }}
        InputProps={{
          endAdornment: (
            <IconButton
              style={{ border: "none", padding: "2px" }}
              onClick={(e) => {
                e.stopPropagation();
                setSearch(true);
              }}
            >
              <SearchOutlined />
            </IconButton>
          ),
          sx: { fontSize: "16px" },
        }}
      ></TextField>

      <ProviderSelect
        data={providers}
        onClick={handleProviderChange}
        isMain={isMain}
      />
      {filters
        .filter((el) => (isMain ? el.main === isMain : el))
        .map((filter) => {
          if (filter.elementType === "Button") {
            return (
              <Button
                key={filter.id}
                className={`slots-filter__ primary-text-color ${
                  filter.active ? "active" : ""
                }`}
                type="button"
                onClick={() => applyFilter(filter.action)}
                sx={{
                  "&:hover": {
                    backgroundColor: "var(--primary-background-color)",
                  },
                }}
              >
                {filter.name}
              </Button>
            );
          }
        })}
    </Box>
  );
};
