import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import { Footer } from "../../components/Footer/Footer";
import { GameHeader } from "../../components/GameHeader/GameHeader";
import { Loader } from "../../components/loader/loader";
import { verifyAuthUser } from "../../store/models/auth/auth.thunk";
import { GameSelector } from "./game.selector";
import "./game.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

export const Game = ({
  setHidden,
}: {
  setHidden: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [exit, setExit] = useState(false);
  const { t, i18n } = useTranslation();
  // console.log(i18n);
  const [exitType, setExitType] = useState("");
  const game_url = location.pathname.split("/")[2];
  const { userId, currency, slots, isDemo } = useAppSelector(GameSelector);
  const game = slots.copy.filter((slot) => slot.url === game_url)[0];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const backHandler = () => {
    setExit(true);
    setExitType("exit");
  };
  const depositHandler = () => {
    setExit(true);
    setExitType("deposit");
    // navigate("/profile/payments/");
  };
  const registrationHandler = () => {
    setExit(true);
    setExitType("exit");
    navigate("/registration");
  };
  const handleCloseDialog = () => {
    setExit(false);
  };
  const handleGoBack = (e: any) => {
    dispatch(verifyAuthUser());
    // // console.log(e.currentTarget.value);
    // debugger;
    if (exitType === "deposit") {
      navigate("/profile/payments/");
    } else {
      navigate("/");
    }
  };
  useEffect(() => {
    setHidden(true);
    window.scrollTo(0, 0);
    return () => {
      setHidden(false);
    };
  }, []);
  const isBig = useMediaQuery({ minWidth: 1599 });

  if (!game || !currency) {
    return <Loader />;
  }

  // debugger;

  return (
    <>
      <Box className="game">
        <GameHeader
          demo={isDemo}
          backHandler={backHandler}
          depositHandler={depositHandler}
          registrationHandler={registrationHandler}
        />
        <Container maxWidth={false} disableGutters>
          <iframe
            title={game_url}
            src={
              isDemo
                ? `https://int.apiforb2b.com/games/${game_url}?operator_id=0&currency=&user_id=0&auth_token=`
                : `https://int.apiforb2b.com/games/${game_url}?operator_id=${
                    process.env.REACT_APP_B2B_OPERATOR_ID
                  }&user_id=${userId}&auth_token=${localStorage.getItem(
                    "game-token"
                  )}&currency=${currency}&language=EN`
            }
            style={{
              height: `calc(100vh - 100px)`,
              width: "100%",
              border: "none",
            }}
          />
        </Container>
      </Box>
      <Dialog
        open={exit}
        onClose={handleCloseDialog}
        className="game-dialog"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("slots.exit.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("slots.exit.text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={"Button Button-secondary"} onClick={handleGoBack}>
            {t("buttons.yes")}
          </Button>
          <Button
            className={
              "Button Button-primary game-dialog-action secondary-hover"
            }
            onClick={handleCloseDialog}
          >
            {t("buttons.no")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
