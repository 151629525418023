import { Button } from "@mui/material";
import "./Button.css";

type IButton = {
  text: string;
  styling: "primary" | "secondary";
  onClick?: () => void;
  size?: "small" | "medium" | "large" | undefined;
  style?: any;
  className?: string;
};

const MButton: React.FC<IButton> = ({
  text,
  styling,
  onClick,
  size,
  style,
  className,
}) => {
  return (
    <Button
      className={"Button" + ` Button-${styling} ${className ? className : ""}`}
      style={style}
      type="submit"
      fullWidth
      size={size}
      variant="contained"
      sx={{ mt: 3, mb: 2, margin: "0.5rem" }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default MButton;
