import axios from "axios";
import { API_URL } from "../..";
import { RequestService } from "../../store/models/request/request.service";
// import { API_URL } from "../fetch";
import {
  IProfileResponse,
  IProfileService,
  IVerificationResponse,
} from "./typing/profile.interface";
import {
  TAddPhone,
  TChangeWallet,
  TSavePersonalInfo,
  TVerification,
} from "./typing/profile.type";

export class ProfileService implements IProfileService {
  public savePersonalIngo: TSavePersonalInfo = async (
    name,
    surname,
    secondname,
    date,
    sex
  ) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/profile/`);

    return await request.run<IProfileResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
      data: {
        name,
        surname,
        secondname,
        date,
        sex,
      },
    });
  };
  public addPhone: TAddPhone = async (phone) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/profile/add_phone`);

    return await request.run<IProfileResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
      data: {
        phone,
      },
    });
  };
  public changeWallet: TChangeWallet = async (walletCurrency) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/profile/changewallet`);

    return await request.run<IProfileResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
      data: {
        currency: walletCurrency,
      },
    });
  };

  // public verification: TVerification = async (files: any[]) => {
  public verification: TVerification = async (
    files: {
      passport: File | null;
      selfie: File | null;
      additional: File | null;
    },
    userId: string
  ) => {
    const request = new RequestService();
    const url = request.prepareUrl(`${API_URL}/api/profile/verification`);

    const data = {
      ...files,
      userId,
    };

    return await request.run<IVerificationResponse>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
        "Content-Type": ["multipart/form-data"],
      },

      data: data,
    });
  };
}
