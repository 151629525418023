export enum BonusesAction {
  ADD_BONUSES = "addBonuses",
  // REMOVE_BONUSES = "removeBonuses",
  ADD_AVAILABLE_BONUSES = "addAvailableBonuses",
  ADD_MY_ACTIVATED_BONUSES = "addMyActivatedBonuses",
  ADD_BONUS_BG_IMAGE = "addBonusBGImage",
  ADD_BONUS_SUB_IMAGE = "addBonusSubImage",
  ADD_MY_ACTIVATED_BONUS_SUB_IMAGE = "addMyActivatedBonusSubImage",
  ADD_AVAILABLE_BONUS_BG_IMAGE = "addAvailableBonusBGImage",
  ADD_AVAILABLE_BONUS_SUB_IMAGE = "addAvailableBonusSubImage",
  FETCH_BONUSES = "getBonuses",
  FETCH_MY_ACTIVATED_BONUSES = "fetchMyActivatedBonuses",
  FETCH_AVAILABLE_BONUSES = "fetchAvailableBonuses",
  FETCH_BONUSES_IMAGES = "fetchBonusesImages",
  FETCH_MY_ACTIVATED_BONUSES_IMAGES = "fetchMyActivatedBonusesImages",
  ACTIVATE_GLOBAL_BONUS = "activateGlobalBunus",
  FETCH_BONUS_BY_ID = "fetchBonusById",
}
