import React from "react";
import { Box, InputAdornment, Typography } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { IInputTelProps } from "./typing/types";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import "./inputTel.css";

export const InputTel = ({
  error,
  touched,
  name,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  confirmed,
}: IInputTelProps) => {
  return (
    <Box className="profile-input-tel">
      <label htmlFor={name} className="profile-input-tel__label">
        {label}
      </label>
      <MuiTelInput
        error={Boolean(error && touched)}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        id={name}
        disabled={disabled}
        sx={{ width: "100%" }}
        InputProps={
          error && touched
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <ErrorOutlineOutlinedIcon
                      style={{
                        color: "rgba(223, 0, 67, 1)",
                        fontWeight: 700,
                      }}
                    />
                  </InputAdornment>
                ),
              }
            : confirmed
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <CheckCircleOutlineIcon
                      style={{
                        color: "rgba(128, 223, 0, 1)",
                        fontWeight: 700,
                      }}
                    />
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {error && touched ? (
        <Typography className="Typography-error" component="p" variant="body2">
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};
