import React from "react";
import { Box, ListItemButton } from "@mui/material";
import { NavLink } from "react-router-dom";
import "./ProfileHeader.css";
interface IProfileHeaderProps {
  links: ILinkItem[];
}
interface ILinkItem {
  link: string;
  text: string;
}
export const ProfileHeader = ({ links }: IProfileHeaderProps) => {
  return (
    <Box className="profile-header">
      <nav className="profile-header__list">
        {links.map((item, index) => {
          if (index === 0 || index === 1) {
            return (
              <ListItemButton key={index} className="profile-header__item">
                <NavLink
                  to={item.link}
                  className="profile-header__item-link"
                  end
                >
                  {item.text}
                </NavLink>
              </ListItemButton>
            );
          }
          return (
            <ListItemButton key={index} className="profile-header__item">
              <NavLink to={item.link} className="profile-header__item-link">
                {item.text}
              </NavLink>
            </ListItemButton>
          );
        })}
      </nav>
    </Box>
  );
};
