import { createAsyncThunk } from "@reduxjs/toolkit";
import { setProvider, setSlots } from "./slot.slice";
import { SlotAction } from "./typing/slot.enum";
import { SlotsService } from "../../../services/slots/slots.service";

export const getSlots = createAsyncThunk(
  SlotAction.GET_SLOTS,
  async (_, { getState, dispatch }) => {
    try {
      const slotsService = new SlotsService();
      const response = await slotsService.fetchSlots();
      if (response.error || !response.result) {
        throw new Error(response.error);
      }
      dispatch(setSlots(response.result));

      return response.result;
    } catch (error) {
      if (error instanceof Error) {
        console.log(error.message);
      }
    }
  }
);
export const getProviders = createAsyncThunk(
  SlotAction.GET_SLOTS,
  async (_, { getState, dispatch }) => {
    try {
      const slotsService = new SlotsService();
      const response = await slotsService.fetchProviders();
      if (response.error || !response.result) {
        throw new Error(response.error);
      }
      dispatch(setProvider(response.result));
      return response.result;
    } catch (error) {
      if (error instanceof Error) {
        // console.log(error.message);
      }
    }
  }
);

export const verifyRestrictions = createAsyncThunk(
  SlotAction.VERIFY,
  async () => {
    const slotsService = new SlotsService();

    const { result, error } = await slotsService.verifyRestrictions();
    if (error && error.response.status === 400) {
      return error.response.data;
    }
    if (error) {
      return { error: error.message };
    }
    if (result) {
      return result.slots;
    }
  }
);
