import { PayloadAction } from "@reduxjs/toolkit";
import { AuthAction } from "./typing/auth.enum";
import { IAuth } from "./typing/auth.interface";

const reducers = {
  [AuthAction.SET_AUTH]: (
    state: IAuth,
    action: PayloadAction<boolean>
  ): void => {
    state.auth = action.payload;
    // // console.log(state);
  },
  [AuthAction.SET_VERIFYED]: (
    state: IAuth,
    action: PayloadAction<boolean>
  ): void => {
    state.isVerifyed = action.payload;
  },
  [AuthAction.SET_AUTHORIZATION_MODAL]: (
    state: IAuth,
    action: PayloadAction<boolean>
  ): void => {
    // debugger;
    state.authorizationModal = action.payload;
  },
  [AuthAction.SET_REGISTRATION_MODAL]: (
    state: IAuth,
    action: PayloadAction<boolean>
  ): void => {
    state.registrationModal = action.payload;
  },
  [AuthAction.SET_PASSWORD_MODAL]: (
    state: IAuth,
    action: PayloadAction<boolean>
  ): void => {
    state.passwordModal = action.payload;
  },
  [AuthAction.SET_INFO_MODAL]: (
    state: IAuth,
    action: PayloadAction<boolean>
  ): void => {
    state.infoModal = action.payload;
  },
  [AuthAction.SET_REDIRECT]: (
    state: IAuth,
    action: PayloadAction<string>
  ): void => {
    state.redirect = action.payload;
  },
  // [AuthAction.ACTIVATIONEMAIL]: (state: IAuth): void => {
  //   // console.log(state);
  // },
  // [AuthAction.RESETLINK]: (state: IAuth): void => {
  //   // console.log(state);
  // },
  // [AuthAction.RESETPASSWORD]: (state: IAuth): void => {
  //   // console.log(state);
  // },
};

export default reducers;
