import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks/store.hooks";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { Loader } from "../loader/loader";
import { activateEmail } from "../../store/models/auth/auth.thunk";

type IActivationProps = {
  param: string | null;
  loading: boolean;
  handleClose: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Activation = ({
  param,
  loading,
  handleClose,
  setLoading,
}: IActivationProps) => {
  const dispatch = useAppDispatch();
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState({ type: "", message: "" });
  const HandleSubmit = async (param: string, { setMessage }: any) => {
    const response: any = await dispatch(activateEmail({ param }));
    // console.log(response);
    if (response?.payload?.error === "Invalid activation link") {
      setMessage({
        type: "invalid",
        message: t("activation.result.invalid"),
      });
    } else if (response?.payload?.error === "Email already confirmed") {
      setMessage({
        type: "activated",
        message: t("activation.result.activated"),
      });
    } else {
      setMessage({
        type: "unexpected",
        message: t("activation.result.unexpected"),
      });
    }
    if (response?.payload?.message === "Email successfully activated") {
      setMessage({ type: "success", message: t("activation.result.success") });
    }
    setLoading(false);
  };
  useEffect(() => {
    if (param) {
      HandleSubmit(param, { setMessage });
    }
  }, []);
  // console.log(message);
  return (
    <>
      <Dialog
        open={!!param}
        onClose={handleClose}
        sx={{
          height: "100vh",
          overflowY: "auto",
          ".MuiDialog-container": {
            alignItems: "center",
          },
        }}
        PaperProps={{
          sx: {
            margin: isMobile ? "106px 14px 0px 14px" : "0px 14px 0px 14px",
            width: "100%",
            maxWidth: "400px",
            height: "auto",
            maxHeight: "100%",
            minHeight: "250px",
            borderRadius: "var(--primary-border-radius)",
            overflow: "hidden",
          },
        }}
      >
        <DialogTitle
          align="center"
          sx={{
            borderRadius:
              "var(--primary-border-radius) var(--primary-border-radius) 0 0",
            backgroundColor: "var(--secondary-background-color)",
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "20px",
            letterSpacing: "2px !important",
            textTransform: "uppercase",
            padding: "21px 0",
            position: "relative",
          }}
        >
          {t("activation.title")}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: "14px",
              top: "50%",
              width: "20px",
              height: "20px",
              transform: "translate(0, -50%)",
              backgroundColor: "var(--primary-background-color)",
              padding: "0",
              borderRadius: "4px !important",
            }}
          >
            <CloseIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          className="dialog-content"
          sx={{
            padding: "24px 40px 36px",
            overflow: "unset",
            borderRadius:
              "0 0 var(--primary-border-radius) var(--primary-border-radius)",
          }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                marginTop: "15px",
              }}
            >
              <Box>
                {message.message.split("\n").map((line, index) => (
                  <Typography
                    key={index}
                    variant="body2"
                    sx={{
                      textAlign: "center",
                      fontWeight: "500 !important",
                      fontSize: "14px",
                      ":last-child": {
                        margin: "24px 0 48px",
                      },
                    }}
                  >
                    {line}
                  </Typography>
                ))}
              </Box>
              <Button
                variant="contained"
                color="primary"
                sx={{ padding: "6px 26px" }}
                onClick={handleClose}
              >
                {t("activation.button")}
              </Button>
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
