// import { API_URL } from "../fetch";
import { RequestService } from "../../store/models/request/request.service";
import { IBonusesService } from "./typing/bonuses.interface";
import { IBonus } from "../../store/models/bonuses/typing/bonuses.interface";
import { API_URL } from "../..";
import { IResponse } from "../../store/models/request/typing/request.interface";
import { error } from "console";

export class BonusesService implements IBonusesService {
  public fetchBonuses = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus`);

    return await request.run<IBonus[]>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public fetchActiveBonuses = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus/mybonuses`);

    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public fetchAvailableBonuses = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus/available`, {});

    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public activateBonus = async (promocode: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/bonus/${promocode}`);

    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public fetchBonusImage = async (image_name: string) => {
    const response = await fetch(
      `${API_URL}/api/bonus/image?image_name=${image_name}`
    );
    const blob = await response.blob();
    return blob;
  };

  public fetchBonusByID = async (bonus_id: number, isMobile: boolean) => {
    const request = new RequestService();

    const url = request.prepareUrl(
      `${API_URL}/api/bonus/get-bonus-by-id/?bonus_id=${bonus_id}`
    );

    const response = await request.run<{ bonus: IBonus }>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
    if (response.result) {
      const blob = await this.fetchBonusImage(
        isMobile
          ? response.result.bonus.images.mobile.sub_image.name
          : response.result.bonus.images.desktop.sub_image.name
      );

      return isMobile
        ? {
            result: {
              ...response.result.bonus,
              images: {
                sub_image: {
                  name: response.result?.bonus.images.mobile.sub_image.name,
                  src: blob,
                },
              },
            },
            error: undefined,
          }
        : {
            result: {
              ...response.result.bonus,
              images: {
                sub_image: {
                  name: response.result?.bonus.images.desktop.sub_image.name,
                  src: blob,
                },
              },
            },
          };
    }
    if (response.error) {
      return {
        result: null,
        error: response.error,
      };
    }
    return {
      result: null,
      error: undefined,
    };
  };
}
