import { createSlice } from "@reduxjs/toolkit";
import reducers from "./bonuses.reducers";
import { IBonus, IBonuses } from "./typing/bonuses.interface";

const defaultBonus = {
  id: 0,
  title: "ПРОМО-КОД",
  active: true,
  type: "all",
  sub_type: "common",
  sub_title: "ЧТОБЫ АКТИВИРОВАТЬ ПРОМО-КОД,ВВЕДИТЕ ЕГО В ПОЛЕ ВВОДА",
  start_date: "",
  end_date: "",
  duration: 0,
  description: "",
  promo_code: undefined,
  images: {
    desktop: {
      bg_image: {
        name: "bg_image_1707552216335",
        src: "",
      },
      sub_image: {
        name: "sub_image_1707552218046",
        src: "",
      },
    },
    mobile: {
      bg_image: {
        name: "mobile_bg_image_1707552217759",
        src: "",
      },
      sub_image: {
        name: "mobile_sub_image_1707552218376",
        src: "",
      },
    },
  },
  wager_fs: 0,
  wager_pers: 0,
} as IBonus;

const initialState: IBonuses = {
  bonuses: [defaultBonus],
  activatedBonuses: [],
  available: [],
};

export const bonusesSlice = createSlice({
  name: "bonuses",
  initialState,
  reducers,
});

// Action creators are generated for each case reducer function
export const {
  // removeBonuses,
  addBonuses,
  addBonusBGImage,
  addBonusSubImage,
  addMyActivatedBonuses,
  addMyActivatedBonusSubImage,
  addAvailableBonuses,
  addAvailableBonusBGImage,
  addAvailableBonusSubImage,
} = bonusesSlice.actions;

export default bonusesSlice.reducer;
