import { Container } from "@mui/material";
import React, { useState } from "react";
import { SlotFilters } from "./SlotFilters/SlotFilters";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import { SlotCategories } from "./SlotCategories/SlotCategories";
import { IStoreSlot } from "../../store/models/slots/typing/slot.interface";
import "./Slots.css";
import { SlotFiltrationResult } from "./SlotFiltrationResult/SlotFiltrationResult";
import { Providers } from "./Providers/Providers";
import { SlotsSelector } from "./slots.selector";
import { useMediaQuery } from "react-responsive";
import { SlotFiltersCategory } from "./SlotFiltersCategory/SlotFiltersCategory";
export const Slots = () => {
  const { copy, providers, isFiltered } = useAppSelector(SlotsSelector);
  const [active, setActive] = useState<number | null>(null);
  const isBig = useMediaQuery({ minWidth: 1599 });
  const dispatch = useAppDispatch();

  function popHandler(games: IStoreSlot[]) {
    return games.filter((game) => game.popular === true);
  }
  function newHandler(games: IStoreSlot[]) {
    return games.filter((game) => game.new === true);
  }
  function otherSlotsHandler(games: IStoreSlot[]) {
    return games.filter((game) => game.popular !== true && game.new !== true);
  }
  const setFilterHandler = (title: string) => {};
  return (
    <div className="slots">
      <Container
        className="container"
        maxWidth={false}
        disableGutters
        // style={{
        //   padding: "0 50px",
        // }}
        //</div>maxWidth={isBig ? "xl" : "lg"}
      >
        <SlotFilters isMain={true} />
        <>
          <SlotCategories
            setFilter={setFilterHandler}
            isMain={true}
            heading="Популярные"
            games={popHandler(copy)}
            active={active}
            setActive={setActive}
            action="filterByPopularity"
          />
          <SlotFiltersCategory isMain={true} />
          <SlotCategories
            setFilter={setFilterHandler}
            isMain={true}
            heading="Слоты"
            games={otherSlotsHandler(copy)}
            active={active}
            setActive={setActive}
            action="filterBySlots"
          />
          <SlotCategories
            setFilter={setFilterHandler}
            isMain={true}
            heading="Новинки"
            games={newHandler(copy)}
            active={active}
            setActive={setActive}
            action="filterByNew"
          />
        </>
        {/* )} */}
      </Container>
      <Providers />
    </div>
  );
};
