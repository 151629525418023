import { Box, Card, CardMedia, Container } from "@mui/material";
import React from "react";
import Slider from "react-slick";

import amatic from "../../../assets/providers/amatic.svg";
import belatra from "../../../assets/providers/belatra.svg";
import betinhell from "../../../assets/providers/betinhell.webp";
import habanero from "../../../assets/providers/habanero.svg";
import igrosoft from "../../../assets/providers/igrosoft.svg";
import megajack from "../../../assets/providers/megajack.png";
import microgaming from "../../../assets/providers/microgaming.svg";
import netent from "../../../assets/providers/netent.svg";
import novomatic from "../../../assets/providers/novomatic.svg";
import playngo from "../../../assets/providers/playngo.svg";
import playtech from "../../../assets/providers/playtech.webp";
import pragmatic from "../../../assets/providers/pragmatic.svg";
import spinomenal from "../../../assets/providers/spinomenal.svg";
import unicum from "../../../assets/providers/unicum.svg";
import yggdrasil from "../../../assets/providers/yggdrasil.svg";
import "./Providers.css";
import { useMediaQuery } from "react-responsive";

var ProviderItem = [
  {
    title: "Amatic",
    url: `${amatic}`,
  },
  {
    title: "belatra",
    url: `${belatra}`,
  },
  {
    title: "betinhell",
    url: `${betinhell}`,
  },
  {
    title: "habanero",
    url: `${habanero}`,
  },
  {
    title: "igrosoft",
    url: `${igrosoft}`,
  },
  {
    title: "megajack",
    url: `${megajack}`,
  },
  {
    title: "microgaming",
    url: `${microgaming}`,
  },
  {
    title: "netent",
    url: `${netent}`,
  },
  {
    title: "novomatic",
    url: `${novomatic}`,
  },
  {
    title: "playngo",
    url: `${playngo}`,
  },
  {
    title: "playtech",
    url: `${playtech}`,
  },
  {
    title: "pragmatic",
    url: `${pragmatic}`,
  },
  {
    title: "spinomenal",
    url: `${spinomenal}`,
  },
  {
    title: "unicum",
    url: `${unicum}`,
  },
  {
    title: "yggdrasil",
    url: `${yggdrasil}`,
  },
];

export const Providers = () => {
  const isBig = useMediaQuery({ minWidth: 1599 });
  return (
    <div className="provider">
      <Container className="container" maxWidth={false} disableGutters>
        <h1 className="provider__heading">провайдеры</h1>
        <Box
          style={{ padding: "0 42px" }}
          sx={{ display: { xs: "none", sm: "block" } }}
        >
          <Slider
            dots={false}
            infinite
            speed={1000}
            swipeToSlide
            autoplay
            autoplaySpeed={5000}
            variableWidth
            pauseOnHover
            centerMode
            draggable
          >
            {ProviderItem.map((item, i) => (
              <ProviderCard key={i} url={item.url} title={item.title} />
            ))}
          </Slider>
        </Box>
      </Container>
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <Slider
          dots={false}
          infinite
          speed={1000}
          slidesToScroll={1}
          autoplay
          autoplaySpeed={5000}
          variableWidth
          pauseOnHover
          arrows={false}
        >
          {ProviderItem.map((item, i) => (
            <ProviderCard key={i} url={item.url} title={item.title} />
          ))}
        </Slider>
      </Box>
    </div>
  );
};

export const ProviderCard = ({
  url,
  title,
}: {
  url: string;
  title: string;
}) => {
  return (
    <Card className="provider-card">
      <CardMedia
        className="provider-card__image"
        component="img"
        image={url}
        alt={title}
      />
    </Card>
  );
};
