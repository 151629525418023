import { createSlice } from "@reduxjs/toolkit";
import reducers from "./auth.reducers";
// import reducers from "./counter.reducers";
import { IAuth } from "./typing/auth.interface";

const initialState: IAuth = {
  auth: false,
  isVerifyed: null,
  authorizationModal: false,
  registrationModal: false,
  passwordModal: false,
  infoModal: false,
  redirect: "",
};

export const authSlice = createSlice({
  name: "authorization",
  initialState,
  reducers,
});

// Action creators are generated for each case reducer function
export const {
  setAuth,
  setVerifyed,
  setAuthorizationModal,
  setRegistrationModal,
  setPasswordModal,
  setInfoModal,
  setRedirect,
} = authSlice.actions;

export default authSlice.reducer;
