import React from "react";
import { Box, CircularProgress } from "@mui/material";

import "./ProfileSubmitButton.css";
interface IProfileSubmitButtonProps {
  disabled?: boolean;
  text: string;
  loading: boolean;
}
export const ProfileSubmitButton = ({
  disabled,
  text,
  loading,
}: IProfileSubmitButtonProps) => {
  return (
    <Box className="profile-submit-button__container">
      <button
        type="submit"
        className="profile-submit-button"
        disabled={disabled}
        style={{ cursor: "pointer" }}
      >
        {loading ? (
          <CircularProgress
            style={{
              height: "25px",
              width: "25px",
              color: "rgb(223, 0, 67)",
            }}
          />
        ) : (
          text
        )}
      </button>
    </Box>
  );
};
