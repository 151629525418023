import React from "react";
import { Box, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type IBonusShortRuleProps = {
  active_bonus: boolean;
  open: boolean;
  close: () => void;
  title: string;
  min_dep: number;
  fs?: {
    count: number;
    game: string;
  };
  pers?: {
    count: number;
    max: number;
    min: number;
  };
  wager_fs: number;
  wager_pers: number;
  detailedHandler: () => void;
  getBonus: () => void;
  playBonus: () => void;
};

export const BonusShortRule = ({
  active_bonus,
  open,
  title,
  min_dep,
  fs,
  pers,
  wager_fs,
  wager_pers,
  close,
  detailedHandler,
  getBonus,
  playBonus,
}: IBonusShortRuleProps) => {
  return (
    <Box className={`bonus-card__bonus-rule ${open && "open"}`}>
      <Box className="bonus-card__bonus-rule-container">
        <Box className="bonus-card__bonus-rule-header" onClick={close}>
          <CloseIcon />
        </Box>
        <Box className="bonus-card__bonus-rule-title">
          <Typography variant="h1" className="bonus-card__bonus-title">
            {title}
          </Typography>
        </Box>
        <Box className="bonus-card__bonus-rule-description">
          <Box className="bonus-card__bonus-rule-description-item">
            Для получения этого бонуса тебе необходимо:
          </Box>
          <Box className="bonus-card__bonus-rule-description-item">
            Быть авторизованным на сайте и нажать на кнопку "Активировать" или
            "Получить бонус"
          </Box>
          {!!min_dep && (
            <Box className="bonus-card__bonus-rule-description-item">
              {`Внести минимальный депозит от ${min_dep} одним платежом.`}
            </Box>
          )}
          {fs && (
            <>
              <Box className="bonus-card__bonus-rule-description-item">
                {`Количество бесплатных спинов: ${fs.count}.`}
              </Box>
              <Box className="bonus-card__bonus-rule-description-item">
                {`Открыть игру ${fs.game}.`}
              </Box>
            </>
          )}
          {pers && (
            <>
              <Box className="bonus-card__bonus-rule-description-item">
                {`Сумма бонуса составляет: ${pers.count}% от твоего депозита.`}
              </Box>
              <Box className="bonus-card__bonus-rule-description-item">
                {`Максимальная сумма бонуса: ${pers.max}.`}
              </Box>
              <Box className="bonus-card__bonus-rule-description-item">
                {`Минимальная сумма бонуса ${pers.min}.`}
              </Box>
            </>
          )}
          {!!fs && !!pers ? (
            <>
              <Box className="bonus-card__bonus-rule-description-item">
                {`Отыгрыш бонуса со спинов: ${wager_fs}х.`}
              </Box>
              <Box className="bonus-card__bonus-rule-description-item">
                {`Отыгрыш бонуса к депозиту: ${wager_pers}х.`}
              </Box>
            </>
          ) : fs ? (
            <Box className="bonus-card__bonus-rule-description-item">
              {`Отыгрыш бонуса со спинов: ${wager_fs}х.`}
            </Box>
          ) : (
            <Box className="bonus-card__bonus-rule-description-item">
              {`Отыгрыш бонуса к депозиту: ${wager_pers}х.`}
            </Box>
          )}
          <Box className="bonus-card__bonus-rule-description-detailed">
            <button
              className="bonus-card__bonus-rules-button"
              onClick={detailedHandler}
            >
              Детальнее...
            </button>
          </Box>
        </Box>
      </Box>
      <Box>
        {active_bonus ? (
          <button className="bonus-card__bonus-rule-button" onClick={playBonus}>
            Играть
          </button>
        ) : (
          <button className="bonus-card__bonus-rule-button" onClick={getBonus}>
            Активировать
          </button>
        )}
      </Box>
    </Box>
  );
};
