import { PayloadAction } from "@reduxjs/toolkit";
import { PaymentAction } from "./typing/payment.enum";
import { IHistoryPayload, IMethod, IPayment } from "./typing/payment.interface";

const reducers = {
  [PaymentAction.SET_DEPOSIT_HISTORY]: (
    state: IPayment,
    action: PayloadAction<IHistoryPayload>
  ): void => {
    if (action.payload.page > state.paymentHistory.deposits.page) {
      state.paymentHistory.deposits.payments.push(...action.payload.payments);
      state.paymentHistory.deposits.count = action.payload.count;
      state.paymentHistory.deposits.page = action.payload.page;
    }
  },

  [PaymentAction.SET_PAYMENT_METHODS]: (
    state: IPayment,
    action: PayloadAction<{ methods: IMethod[] }>
  ): void => {
    state.paymentMethods.methods = [...action.payload.methods];
  },

  [PaymentAction.SET_WITHDRAWAL_HISTORY]: (
    state: IPayment,
    action: PayloadAction<IHistoryPayload>
  ): void => {
    if (action.payload.page > state.paymentHistory.withdrawals.page) {
      state.paymentHistory.withdrawals.payments.push(
        ...action.payload.payments
      );
      state.paymentHistory.withdrawals.count = action.payload.count;
      state.paymentHistory.withdrawals.page = action.payload.page;
    }
  },
};

export default reducers;
