import { TSelector } from "../../../../../store/typing/types";
import { IProfilePaymentHistorySelector } from "../typing/ProfilePaymentHistory.interface";

export const ProfilePaymentHistorySelector: TSelector<
  IProfilePaymentHistorySelector
> = ({ user, payments: { paymentHistory } }) => {
  // const history = paymentHistory.map((h, i, arr) => ({
  //   ...h,
  //   // number: paymentHistoryCount - i,
  // }));
  // for (const i in history) {
  //   if (history[i].status === "success") {
  //     history[i].status = "Доставлен";
  //   }
  //   if (history[i].status === "error") {
  //     history[i].status = "Ошибка";
  //   }
  //   if (history[i].status === "pending") {
  //     history[i].status = "Ожидание";
  //   }
  //   if (history[i].type === "refill") {
  //     //@ts-ignore
  //     history[i].transaction = "Депозит";
  //   }
  //   if (history[i].type === "withdrawals") {
  //     //@ts-ignore
  //     history[i].transaction = "Вывод";
  //   }
  // }
  // return {
  //   headers: ["Транзакция", "Сума", "Валюта", "Статус", "Дата"],
  //   history,
  //   count: paymentHistoryCount,
  //   query: paymentHistoryQuery,
  //   state: paymentHistoryState,
  // };
  return {
    deposits: paymentHistory.deposits,
    withdrawals: paymentHistory.withdrawals,
  };
};
