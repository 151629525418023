import { AxiosResponse, Method } from "axios";
// import API from "../../../services/fetch";
import API from "../../../index";
import { IRequestService } from "./typing/request.interface";
import { TPrepareQuery, TPrepareUrl, TRun } from "./typing/request.types";

export class RequestService implements IRequestService {
  public run: TRun = async (url, config) => {
    if (!url)
      return {
        result: null,
        error: Error("Received url is invalid for a endpoint url"),
      };

    try {
      const response: AxiosResponse = await API({
        ...config,
        url,
        method: config?.method as Method,
        headers: {
          ...config?.headers,
          // "Content-Type": ["application/json"],
          accept: ["application/json", "multipart/form-data"],
        },
      });

      const { data, status } = response;

      if (status !== 200)
        return {
          error: Error(`Status is ${response.status}`),
          result: null,
        };

      return { result: data, error: null };
    } catch (error) {
      return { result: null, error };
    }
  };

  public prepareQuery: TPrepareQuery = (query, additional) => {
    const result = new URLSearchParams(query);

    for (const [name = "", value = ""] of Object.entries(additional)) {
      result.append(name, value);
    }

    return result.toString();
  };

  public prepareUrl: TPrepareUrl = (url, options = {}) => {
    const { query = "", additionalQuery = {} } = options;
    const preparedQuery = this.prepareQuery(query, additionalQuery);

    if (preparedQuery) return `${url}?${preparedQuery}`;
    return `${url}`;
  };
}
