import { createSelector } from "reselect";
import { SlotsService } from "../../services/slots/slots.service";
import { TCategories } from "../../services/slots/typing/slots.type";
import {
  IStoreProvider,
  IStoreSlot,
} from "../../store/models/slots/typing/slot.interface";
import { RootState } from "../../store/rootStore";

interface ISlotsSelector {
  copy: IStoreSlot[];
  providers: IStoreProvider[];
  isFiltered: boolean;
  categories: TCategories[];
  isDemo: boolean;
}

const selectRawData = (state: RootState) => state.slots;

export const SlotsSelector = createSelector(selectRawData, (slots) => {
  const sloteService = new SlotsService(slots);

  const categories = sloteService.getGamesWithCategories();

  return {
    copy: slots.copy,
    providers: slots.providers,
    isFiltered: slots.isFiltered,
    categories,
    isDemo: slots.isDemo,
  } as ISlotsSelector;
});
