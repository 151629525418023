import React from "react";
import { Box, Container, Typography } from "@mui/material";
import "./FooterAllReserved.css";
export const FooterAllReserved = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Box className="footer-all-reserved">
      <Container className="container" maxWidth={false} disableGutters>
        <Typography className="footer-all-reserved__heading">
          © Все права защищены, {currentYear}
        </Typography>
      </Container>
    </Box>
  );
};
