import React, { useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks/store.hooks";
import { verifyRestrictions } from "../store/models/slots/slot.thunk";
import { Loader } from "../components/loader/loader";
import { SlotsBlocked } from "../components/Slots/SlotsBlocked/SlotsBlocked";

export const GameLayout = () => {
  const dispatch = useAppDispatch();
  const [slots, setSlots] = useState<boolean | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState(null);
  const isDemo = useAppSelector((state) => state.slots.isDemo);
  // debugger;

  useEffect(() => {
    const verify = async () => {
      try {
        const response = await dispatch(verifyRestrictions());
        if (response.payload?.error) {
          setLoading(false);
          setError(response.payload.error);
        } else if (response.type === "verify/fulfilled") {
          setLoading(false);
          setSlots(!response.payload);
        }
      } catch (error: any) {
        setLoading(false);
        setError(error.message);
      }
    };
    if (!isDemo) {
      // debugger;
      verify();
    } else {
      // debugger;
      setLoading(false);
    }
  }, [isDemo]);

  // console.log({
  //   slots,
  //   isDemo,
  // });
  return (
    <>
      {isDemo && <Outlet />}
      {slots && slots !== null && <Outlet />}
      {!slots && slots !== null && (
        <SlotsBlocked
          title="Заблокировано"
          text="Доступ к игровой деятельности ограничен. Для уточнения информации обратитесь в Службу Поддержки"
        />
      )}
      {error && slots === null && (
        <SlotsBlocked
          title="Ошибка"
          text="При проведении операции возникла ошибка. Повторите попытку позже"
        />
      )}
      {loading && <Loader />}
    </>
  );
};
