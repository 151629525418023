import React from "react";
import { Box } from "@mui/material";
import "./FooterResponsiveGaming.css";
import age18 from "../../../assets/copyright/18+.svg";
import begamleware from "../../../assets/copyright/begambleaware.svg";
import gamleaware from "../../../assets/copyright/gambleaware.svg";
import gamecare from "../../../assets/copyright/gamecare.svg";
import responsiveGambling from "../../../assets/copyright/responsibly-gaming.svg";
export const FooterResponsiveGaming = () => {
  return (
    <Box className="footer-responsive">
      <ul className="footer-responsive__list">
        <li className="footer-responsive__item">
          <span className="footer-responsive__item-link">
            <img
              src={age18}
              alt="18+"
              className="footer-responsive__item-image"
            />
          </span>
        </li>
        <li className="footer-responsive__item">
          <a
            className="footer-responsive__item-link"
            href="https://www.gamblersanonymous.org/ga/"
            target="blank"
            rel="noindex,nofollow"
          >
            <img
              src={gamleaware}
              alt="begambleaware"
              className="footer-responsive__item-image"
            />
          </a>
        </li>
        <li className="footer-responsive__item">
          <a
            className="footer-responsive__item-link"
            href="https://www.gamcare.org.uk/"
            target="blank"
            rel="noindex,nofollow"
          >
            <img
              src={gamecare}
              alt=""
              className="footer-responsive__item-image"
            />
          </a>
        </li>
        <li className="footer-responsive__item">
          <a
            className="footer-responsive__item-link"
            href="https://www.begambleaware.org/"
            target="blank"
            rel="noindex,nofollow"
          >
            <img
              src={begamleware}
              alt="gamcare"
              className="footer-responsive__item-image"
            />
          </a>
        </li>
        <li className="footer-responsive__item">
          <span className="footer-responsive__item-link">
            <img
              src={responsiveGambling}
              alt="responsive-gambling"
              className="footer-responsive__item-image"
            />
          </span>
        </li>
      </ul>
    </Box>
  );
};
