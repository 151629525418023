import { useEffect } from "react";
import { Loader } from "../../../components/loader/loader";

export const SteamConfirmation = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) localStorage.setItem("game-token", token);
    localStorage.removeItem("enprsl");
    localStorage.removeItem("regkey");
    window.close();
  }, []);

  return <Loader></Loader>;
};
