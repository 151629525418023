import { TSelector } from "../../store/typing/types";
import { IRequestPasswordSelector } from "./typing/RequestPassword.interface";
import { RootState } from "../../store/rootStore";
import { createSelector } from "reselect";

const selectRawData = (state: RootState) => ({
  user: state.user,
  authorization: state.authorization,
  // Add other relevant parts of your state as needed
});

// Create a memoized selector using reselect
export const RequestPasswordSelector = createSelector(
  selectRawData,
  ({ authorization }) => {
    return {
      auth: authorization.auth,
    } as IRequestPasswordSelector;
  }
);
