import { UserService } from "./../user/user.service";

import { IPartnerService } from "./typing/partner.interface";

import CryptoJS from "crypto-js";
import { RequestService } from "../../store/models/request/request.service";
// import { API_URL } from "../fetch";
import { IPartner } from "../../store/models/profile/typing/profile.interface";
import { initialStateProfile } from "../../store/models/profile/profile.slice";
import { API_URL } from "../..";
const secretPass = "dasjjk435bjj35bk345";
// dasjjk435bjj35bk345;

export class PartnerService implements IPartnerService {
  private partner: IPartner;
  constructor(partner: IPartner = initialStateProfile.partner) {
    this.partner = partner;
  }

  public getPartnerId = () => {
    const userService = new UserService();

    return userService.getUserID();
  };

  public getPartnerLinks = () => {
    // const userService = new UserService();
    // debugger;
    return this.partner.links;
  };

  public getGeneralInfo = async (startDate: string, endDate: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/partner`, {
      query: "",
      additionalQuery: {
        startDate,
        endDate,
      },
    });

    return await request.run<any>(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
      },
    });
  };

  public createPartnerLink = async (name: string, bonus: number) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/partner/link`);

    return await request.run<any>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
        "Access-Control-Allow-Credentials": "true",
      },
      data: {
        name,
        bonus,
      },
    });
  };

  public createPartnerPromocode = async (
    name: string,
    promocode: string,
    bonus: number
  ) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/partner/promocode`);

    return await request.run<any>(url, {
      method: "POST",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
        "Access-Control-Allow-Credentials": "true",
      },
      data: {
        name,
        promocode,
        bonus,
      },
    });
  };

  public removePartnerLink = async (link: string) => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/partner/link`);

    return await request.run<any>(url, {
      method: "PUT",
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
        "Access-Control-Allow-Credentials": "true",
      },
      data: {
        link,
      },
    });
  };

  public getPartnerLink = async () => {
    const request = new RequestService();

    const url = request.prepareUrl(`${API_URL}/api/partner/link`);

    return await request.run<any>(url, {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("game-token")}`,
        "Access-Control-Allow-Credentials": "true",
      },
    });
  };
}
