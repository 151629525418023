import * as React from "react";
import AppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import "./Menuappbar.css";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { MenuappbarSelector } from "./Menuappbar.selector";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import Menuappbarlist from "./Menuappbarlist/Menuappbarlist";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Container,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  Menu,
  Typography,
  Button,
} from "@mui/material";
import {
  setAuthorizationModal,
  setRegistrationModal,
} from "../../store/models/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { verifyAuthUser } from "../../store/models/auth/auth.thunk";
import { getProviders, getSlots } from "../../store/models/slots/slot.thunk";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Loader } from "../loader/loader";
import Logo from "../../assets/icons/enomo_logo.svg";
import menu from "../../assets/icons/menu_icon.svg";

const Registration = lazy(() => import("../registration/Registration"));
const Authorization = lazy(() => import("../authorization/Authorization"));
const ResetPassword = lazy(() => import("../resetpassword/ResetPassword"));

interface MenuAppBar {
  hidden: boolean;
}

const MenuAppBar: React.FC<MenuAppBar> = ({ hidden }) => {
  const { t, i18n } = useTranslation();

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [mounted, setMounted] = useState(false);
  const dispatch = useAppDispatch();

  const handleVerifyAuthUser = useCallback(() => {
    if (!mounted) {
      dispatch(verifyAuthUser());
      setMounted(true);
    }
  }, [dispatch, mounted]);

  useEffect(() => {
    handleVerifyAuthUser();
  }, [handleVerifyAuthUser]);

  useEffect(() => {
    dispatch(getSlots());
    dispatch(getProviders());
  }, [dispatch]);

  const navigate = useNavigate();
  const { auth, isVerifyed, userBalance, userCurrency } =
    useAppSelector(MenuappbarSelector);
  const isMobile = useMediaQuery({ maxWidth: 599 });
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
    setIsOpen(true);
  };
  const isBig = useMediaQuery({ minWidth: 1599 });

  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
    setIsOpen(false);
  }, []);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: hidden ? "none" : "block",
      }}
    >
      <AppBar
        className={"appbar"}
        position="static"
        style={{
          borderBottom: "1px solid",
        }}
        sx={{
          minHeight: { sm: "84px", xs: "56px" },
          justifyContent: "center",
        }}
      >
        <Container className="container" maxWidth={false} disableGutters>
          <Box
            // style={{ borderBottom: "1px solid" }}
            sx={{
              flexGrow: 0,
              alignItems: "center",
              display: "flex",
              flexWrap: { xs: "wrap", sm: "none" },
              justifyContent: "space-between",
            }}
          >
            <Box
              onClick={() => navigate("/")}
              sx={{
                mr: 2,
                display: "flex",
              }}
            >
              <img src={Logo} alt="Enomo" className="appbar-logo" />
            </Box>
            <Box
              display="flex"
              alignItems={"center"}
              sx={{
                width: { sm: "fit-content" },
              }}
            >
              {isVerifyed ? (
                <Box>
                  {auth ? (
                    <Box sx={{ display: { sm: "flex", xs: "none" } }}>
                      <Button
                        className="Button Button-secondary primary-hover"
                        style={{
                          minWidth: "max-content",
                          margin: " 0 9px 0 0",
                          padding: "6px 23px",
                        }}
                        onClick={() => {
                          navigate("/profile/payments");
                        }}
                      >
                        {t("buttons.topup")}
                      </Button>
                      <Button
                        className="Button Button-primary secondary-hover"
                        style={{
                          minWidth: "max-content",
                          margin: " 0 9px",
                          padding: "6px 23px",
                        }}
                      >
                        {`${userBalance} ${userCurrency}`}
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ display: { sm: "flex", xs: "none" } }}>
                      <Button
                        style={{ margin: " 0 9px", padding: "6px 23px" }}
                        className="Button Button-secondary pulse-button primary-hover"
                        onClick={() => {
                          dispatch(setRegistrationModal(true));
                        }}
                      >
                        {t("registration.title")}
                      </Button>
                      <Button
                        style={{ margin: " 0 9px", padding: "6px 23px" }}
                        className="Button Button-primary secondary-hover"
                        onClick={() => {
                          dispatch(setAuthorizationModal(true));
                        }}
                      >
                        {t("authorization.title")}
                      </Button>
                    </Box>
                  )}
                </Box>
              ) : null}
              <IconButton
                sx={{
                  padding: "8.5px 15px",
                  backgroundColor: {
                    xs: "inherit",
                    sm: "var(--secondary-background-color)",
                  },
                  borderRadius: "10px",
                  border: { xs: "none", sm: "var(--primary-border)" },
                  marginLeft: "12px",
                }}
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
              >
                <img src={menu} alt="Menu" />
                {/* <div className={`menu-button ${isOpen ? "active" : ""}`}>
                  <svg
                    className="menu-icon"
                    width="40"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      className="dot"
                      cx="-2"
                      cy="2"
                      r="2"
                      fill="var(--primary-color)"
                    />
                    <rect
                      className="line firstline"
                      x={`${isOpen ? "7" : "5"}`}
                      y="0"
                      height="2"
                      rx="1"
                      fill="var(--primary-text-color)"
                    />

                    <circle
                      className="dot"
                      cx="-2"
                      cy="8"
                      r="2"
                      fill="var(--primary-text-color)"
                    />
                    <rect
                      className="line middleline"
                      x={`${isOpen ? "0" : "5"}`}
                      y="7"
                      height="2"
                      rx="1"
                      fill="var(--primary-color)"
                    />

                    <circle
                      className="dot"
                      cx="-2"
                      cy="14"
                      r="2"
                      fill="var(--primary-color)"
                    />
                    <rect
                      className="line lastline"
                      x={`${isOpen ? "0" : "5"}`}
                      y="14"
                      height="2"
                      rx="1"
                      fill="var(--primary-text-color)"
                    />
                  </svg>
                </div> */}
              </IconButton>
            </Box>

            <Menu
              id="menu-appbar"
              className="primary-text-color"
              anchorEl={anchorElNav}
              keepMounted
              marginThreshold={0}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              slotProps={{
                root: {
                  sx: {
                    position: "fixed",
                    height: "100vh",
                    width: "100%",
                    padding: "0",
                    top: "0",
                    right: "0",
                    margin: "0",
                    backgroundColor: "rgb(0 0 0 / 40%)",
                  },
                },
                paper: {
                  sx: {
                    width: "100%",
                    maxWidth: isMobile ? "100%" : "350px",
                    position: "fixed",
                    // height: { xs: "calc(100% - 84px)", sm: "auto" },
                    height: "100vh",
                    maxHeight: "100%",
                    overflowY: "auto",
                    right: "0",
                    // left: "100%",
                    top: "0px",
                    backgroundColor: "var(--secondary-background-color)",
                    backgroundImage: "var(--secondary-background-color)",
                    margin: "0px 0px",
                    borderRadius: "0",
                    boxShadow: "var(--primary-shadow)",
                  },
                },
              }}
            >
              <Menuappbarlist auth={auth} onClose={handleCloseNavMenu} />
            </Menu>
          </Box>
        </Container>
      </AppBar>
      {isVerifyed ? (
        <Box
          sx={{
            display: { sm: "none", xs: "flex" },
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: { xs: "56px" },
          }}
        >
          {auth ? (
            <Box
              sx={{
                display: { sm: "none", xs: "flex" },
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button
                className="Button Button-secondary primary-hover"
                style={{
                  minWidth: "max-content",
                  maxWidth: "fit-content",
                  padding: "6px 15px",
                }}
                onClick={() => {
                  navigate("/profile/payments");
                }}
              >
                {t("buttons.topup")}
              </Button>
              <Button
                className="Button Button-primary secondary-hover"
                style={{
                  minWidth: "max-content",
                  maxWidth: "fit-content",
                  padding: "6px 15px",
                }}
              >
                {`${userBalance} ${userCurrency}`}
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: { sm: "none", xs: "flex" },
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Button
                style={{ margin: " 0 9px", padding: "6px 15px" }}
                className="Button Button-secondary pulse-button primary-hover"
                onClick={() => {
                  dispatch(setRegistrationModal(true));
                }}
              >
                {t("registration.title")}
              </Button>
              <Button
                style={{ margin: "0 9px", padding: "6px 15px" }}
                className="Button Button-primary secondary-hover"
                onClick={() => {
                  dispatch(setAuthorizationModal(true));
                }}
              >
                {t("authorization.title")}
              </Button>
            </Box>
          )}
        </Box>
      ) : null}
      <div>
        <Suspense fallback={<Loader />}>
          <Registration />
          <Authorization />
          <ResetPassword />
        </Suspense>
      </div>
    </Box>
  );
};

export default MenuAppBar;
