import { useMemo } from "react";
import { createSelector } from "reselect";
import { RootState } from "../../../../store/rootStore";

// Select the raw data from the Redux state
const selectRawData = (state: RootState) => state.bonuses;

// Create a memoized selector using reselect
export const inactiveBonusesListSelector = createSelector(
  selectRawData,
  (bonuses) => ({
    inactivatedBonuses: bonuses.activatedBonuses.filter(
      (bonus) =>
        bonus.status === "expired" ||
        bonus.status === "fail" ||
        bonus.status === "wagered" ||
        bonus.status === "canceled"
    ),
  })
);
