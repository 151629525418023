import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
  InputAdornment,
  Box,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { MySelect } from "../../../select/select";
import { ProfileInput } from "../../../input/input";
import { ProfileMenuSelector } from "../../ProfileMenu/ProfileMenu.selector";
import { IGetBanksPayload, IWithdrawModalProps } from "../typing";
import * as Yup from "yup";
import EuroIcon from "@mui/icons-material/Euro";
import CurrencyRubleIcon from "@mui/icons-material/CurrencyRuble";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

import "./modal.css";
import { Loader } from "../../../loader/loader";

export default function WithdrawalModal({
  open,
  method_type,
  method_id,
  image,
  min,
  max,
  paymentCurrency,
  closeHandler,
  withdrawalHandler,
}: // loading,
IWithdrawModalProps) {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { currency, userBalance } = useAppSelector(ProfileMenuSelector);
  const [banks, setBanks] = useState<{ value: string; text: string }[]>([
    { value: "", text: "" },
  ]);
  const [loading, setLoading] = useState<boolean>(false);

  const PaymentSchema = withdrawalSchema(min, max, paymentCurrency, i18n);

  useEffect(() => {
    async function a() {
      // const data = (await dispatch(getBanks())).payload as IGetBanksPayload[];
      // setBanks(data);
    }
    if (method_id === "201") {
      a();
    }
  }, [method_id]);

  const initialValues =
    method_id === "201"
      ? {
          amount: null as number | null,
          credentials: "",
          bank_id: "",
        }
      : {
          amount: null as number | null,
          credentials: "",
        };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: PaymentSchema,
    onSubmit: async (values, { setErrors }) => {
      setLoading(true);
      if (values.amount) {
        const balance = parseFloat(userBalance.main_balance);
        const amount = values.amount;

        if (balance >= amount) {
          await withdrawalHandler(
            values.amount,
            values.credentials,
            method_id,
            values.bank_id
          );
        } else {
          setErrors({
            amount: t("main.profile.payments.deposit.validation.amount"),
          });
        }
      }
      setLoading(false);
    },
  });
  const allAvailable = () => {
    formik.setValues({
      ...formik.values,
      amount:
        +userBalance.main_balance > max ? +max : +userBalance.main_balance,
    });
  };
  const closeModal = () => {
    formik.setValues({ ...formik.values, amount: null });
    closeHandler();
  };
  const onChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    const regExp = /^\d$/i;
    if (
      regExp.test(value[value.length - 1]) ||
      value[value.length - 1] === "." ||
      value === ""
    ) {
      formik.setValues({ ...formik.values, amount: Number(value) });
    }
  };
  return (
    <>
      <Dialog open={open} id="payment__modal">
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <DialogTitle>Вывод средств</DialogTitle>
          <DialogContent>
            <Typography variant="body1">Метод:</Typography>
            <img src={image} alt="Payment method" />
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: "var(--secondary-text-color)" }}
            >{`(${min} - ${max} ${paymentCurrency})`}</Typography>
          </DialogContent>
          <form onSubmit={formik.handleSubmit} className="payment-modal__form">
            <label htmlFor="amount">
              <Typography
                variant="body1"
                sx={{
                  display: "block",
                  color: "var(--primary-text-color)",
                  fontSize: "14px",
                  lineHeight: " 14px",
                  marginBottom: "8.2px !important",
                  fontWeight: "400 !important",
                }}
              >
                Укажите сумму
              </Typography>
            </label>
            <Box style={{ position: "relative" }}>
              <TextField
                error={Boolean(formik.errors.amount && formik.touched.amount)}
                autoFocus
                autoComplete="off"
                id="amount"
                className="payment-modal__amount"
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.amount}
                onChange={onChangeHandler}
                InputProps={
                  currency === "RUB"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CurrencyRubleIcon
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                marginRight: "10px",
                                fontWeight: 700,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : currency === "EUR"
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            <EuroIcon
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                marginRight: "10px",
                                fontWeight: 700,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }
                    : {
                        endAdornment: (
                          <InputAdornment position="end">
                            <AttachMoneyIcon
                              style={{
                                color: "rgba(255, 255, 255, 0.7)",
                                marginRight: "10px",
                                fontWeight: 700,
                              }}
                            />
                          </InputAdornment>
                        ),
                      }
                }
              />
              {formik.errors.amount && formik.touched.amount ? (
                <Typography
                  className="Typography-error"
                  component="p"
                  variant="body2"
                  sx={{
                    position: "absolute",
                    left: "10px",
                    bottom: "5px",
                  }}
                >
                  {formik.errors.amount}
                </Typography>
              ) : null}
            </Box>
            <Box className="payment-modal__button-container">
              <button
                type="button"
                className="payment-modal__button"
                onClick={allAvailable}
              >
                Все
              </button>
            </Box>

            <Box>
              <ProfileInput
                error={formik.errors.credentials}
                touched={formik.touched.credentials}
                handleChange={formik.handleChange}
                handleBlur={formik.handleBlur}
                value={formik.values.credentials}
                name={"credentials"}
                label={
                  method_type === "mobile"
                    ? "Укажите номер телефона/счета"
                    : method_type === "card"
                    ? "Укажите номер карты"
                    : "Укажите адрес кошелька"
                }
                withoutPlaceholder={true}
              />
            </Box>

            <DialogActions sx={{ justifyContent: "center" }}>
              <button
                className="payment-modal__close"
                type="button"
                onClick={closeModal}
              >
                <CloseIcon
                  style={{
                    color: "rgba(255,255,255,0.7)",
                  }}
                />
              </button>
              <Button
                className="Button Button-secondary primary-hover payment-modal__submit"
                type="submit"
              >
                Вывести
              </Button>
            </DialogActions>
          </form>
        </Box>
        {loading && <Loader />}
      </Dialog>
    </>
  );
}

export function withdrawalSchema(
  min: number,
  max: number,
  currency: string,
  i18n: any
) {
  return Yup.object().shape({
    amount: Yup.number()
      .required(i18n.t("main.profile.payments.deposit.validation.required"))
      .min(
        min,
        i18n.t("main.profile.payments.deposit.validation.min", {
          val: `${min} ${currency}`,
        })
      )
      .max(
        max,
        i18n.t("main.profile.payments.deposit.validation.max", {
          val: `${max} ${currency}`,
        })
      ),
    credentials: Yup.string().required(
      i18n.t("main.profile.payments.deposit.validation.requiredVal")
    ),
    // .matches(
    //   /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|(50|[56-69])[0-9]{10,17})|220[0-4][0-9]{12}|(3528|3529)[0-9]{11}|35[3-8][0-9]{12}|(1800|2131)[0-9]{11}|357266[0-9]{13}$/,
    //   i18n.t("main.profile.payments.deposit.validation.invalidCardNumber")
    // ),
  });
}
