import React from "react";
import { Box, Container, Typography } from "@mui/material";
import "./NotFound.css";
import { Link } from "react-router-dom";
import enomo from "../../assets/icons/enomo.svg";
// import enomo from "../../assets";

interface INotFoundProps {}
export const NotFound = ({}: INotFoundProps) => {
  // const handleNavigate = () => {
  //   history.push("/");
  // };

  return (
    <>
      <Container
        maxWidth={false}
        disableGutters
        className="not_found_container primary-text-color container"
      >
        <Box className="not_found_header">
          <Typography variant="h1">4</Typography>
          <Typography style={{ padding: "0 1rem" }} variant="subtitle2">
            <img src={enomo} alt="Enomo loader" />
          </Typography>
          <Typography variant="h1">4</Typography>
        </Box>
        <Typography variant="h4" className="not_found_subtitle">
          СТРАНИЦА НЕ НАЙДЕНА
        </Typography>
        <Typography variant="body1" className="not_found_text">
          Попробуйте начать с{" "}
          <Link to="/" style={{ cursor: "pointer" }}>
            главной страницы
          </Link>
        </Typography>
      </Container>
    </>
  );
};
