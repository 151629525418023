import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import "./rules.css";
import strawberry from "../../assets/strawberry.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CardMedia,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
// import { Jivo } from "../../components/jivo/Jivo";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  LiveChatWidget,
  EventHandlerPayload,
  useWidgetIsReady,
  useWidgetState,
} from "@livechat/widget-react";
import { IJivoSelector } from "../../components/jivo/typing/jivo.interface";
import { jivoSelector } from "../../components/jivo/jivo.selector";

export const accordionData = [
  {
    id: 0,
    pathname: "policy",
    headerId: "policy-header",
    contentId: "policy-content",
    label: "Политика конфиденциальности",
    labelKey: "footer.navigation.policy",
    text: `
      <h1 style="text-align: center; color: #F8F8F8; font-weight: 500 !important;">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
      <p style="font-weight: 400 !important;">Добро пожаловать на страницу нашей политики конфиденциальности. Мы ценим Ваше доверие и стремимся обеспечить максимальную защиту Ваших личных данных во время использования наших услуг.</p>
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">1. Сбор информации об Игроке</h3>
      <p style="font-weight: 400 !important;">Мы собираем только необходимую информацию, которая помогает нам предоставить Вам наилучший игровой опыт. Это может включать в себя Ваше имя, адрес электронной почты, данные об активности в играх и техническую информацию, такую как IP-адрес и тип браузера. Мы делаем все возможное, чтобы обеспечить безопасность и конфиденциальность Вашей информации.</p>
      <p style="font-weight: 400 !important;">Информация об Игроке собирается во время его регистрации на Веб-сайте, верификации, чтобы открыть счет, или когда Игрок подает письменный запрос.</p>
    
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2. Цель сбора информации</h3>
      <p style="font-weight: 400 !important;">Наша главная цель - создание удобной и безопасной среды для наших Игроков. Мы собираем информацию об Игроках, чтобы обеспечить лучшее качество обслуживания, персонализированные предложения и безопасность игрового процесса.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">3. Использование информации</h3>
      <p style="font-weight: 400 !important;">Мы используем собранную информацию для следующих целей:</p>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Обеспечение функционирования нашей платформы и безопасности Игроков.</li>
          <li>Персонализация игрового опыта и предоставление индивидуальных предложений и акций.</li>
          <li>Анализ поведения Игроков с целью улучшения наших сервисов и разработки новых функциональностей.</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">4. Защита данных</h3>
      <p style="font-weight: 400 !important;">Мы принимаем все необходимые меры для защиты Вашей информации от несанкционированного доступа, использования или раскрытия. Мы используем современные технологии и применяем строгие политики безопасности, чтобы обеспечить надежную защиту Ваших данных.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">5. Обмен информацией</h3>
      <p style="font-weight: 400 !important;">Нам может потребоваться передать Ваши Персональные данные третьей стороне. Передача данных осуществляться следующими способами:</p>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
        <li>Партнерские программы и обработчики платежей: мы может сотрудничать с партнерами или обработчиками платежей, которым могут передаваться некоторые персональные данные Игроков в целях обработки платежей и выполнения финансовых транзакций.</li>
        <li>Маркетинговые и рекламные компании: Операторы Казино могут использовать услуги маркетинговых и рекламных компаний для проведения рекламных кампаний или персонализации рекламы. В этом случае могут передаваться некоторые обобщенные данные о поведении Игроков или их предпочтениях.</li>
        <li>Законные требования: В некоторых случаях операторы Казино могут быть обязаны передавать персональные данные третьим лицам в соответствии с законодательством, например, в ответ на запросы правоохранительных органов или в судебном порядке.</li>
        <li>Аналитические и статистические службы: Операторы Казино могут использовать услуги аналитических компаний для сбора и анализа данных о поведении Игроков с целью улучшения сервиса и оптимизации игрового опыта.</li>
        <li>Обработка данных на стороне: В некоторых случаях персональные данные могут быть переданы третьим лицам для обработки на стороне, например, для хранения данных в облачных сервисах или обеспечения безопасности Сайта и транзакций.</li>
      </ul>
       <p style="font-weight: 400 !important;">Важно отметить, что передача персональных данных третьим лицам осуществляется в соответствии с применимым законодательством о защите данных и политикой конфиденциальности, а также обычно осуществляется только при наличии согласия Игрока или в случаях, предусмотренных законом.</p>
      
       <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">6. Ваше согласие</h3>
      <p style="font-weight: 400 !important;">Используя наши услуги, Игрок признает, что он прочитал и согласен с условиями Политики конфиденциальности. Если у Вас есть какие-либо вопросы или предложения по поводу обработки Вашей информации, пожалуйста, свяжитесь с нами.</p>
      <p style="font-weight: 400 !important;">Спасибо за Ваше доверие и выбор нашего Казино. Мы ценим каждого нашего Игрока и делаем все возможное, чтобы Ваше игровое время было приятным, безопасным и защищенным.</p>
      
       <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">7. Какую информацию мы собираем, и как мы ее используем</h3>
      <p style="font-weight: 400 !important;">Мы понимаем, что сбор информации об Игроках — это ответственность, которая требует четких принципов и целей. Ниже приведены основные причины, по которым мы собираем информацию о наших Игроках:</p>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
        <li>Личные данные: При регистрации на нашем Сайте мы можем запросить Ваше имя, адрес электронной почты, дату рождения и другие личные данные. Эта информация используется исключительно для создания Вашего аккаунта, обеспечения безопасности и предоставления Вам наилучшего игрового опыта.</li>
        <li>Финансовая информация: При совершении депозитов и выводе средств мы можем попросить Вас предоставить информацию о Вашей кредитной карте, банковском счете или других платежных данных. Эти данные используются исключительно для обработки платежей и обеспечения безопасности финансовых транзакций.</li>
        <li>Техническая информация: Мы автоматически собираем определенную техническую информацию, такую как IP-адрес, данные о браузере и операционной системе, а также информацию о Вашем устройстве. Эта информация помогает нам оптимизировать работу Сайта, предотвращать мошенничество и обеспечивать безопасность игрового процесса.</li>
        <li>Куки-файлы: Мы используем куки-файлы для анализа трафика, персонализации контента и рекламы, а также для обеспечения безопасности нашего Сайта. Вы можете отключить куки в настройках Вашего браузера, однако это может повлиять на функциональность некоторых частей нашего Сайта.</li>
        <li>Безопасность данных: Мы принимаем все необходимые меры для защиты Вашей личной и финансовой информации от несанкционированного доступа, использования или раскрытия. Мы строго следуем стандартам безопасности и шифрованию данных.</li>
      </ul>
      
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">8. Обновление и информация об Игроке</h3>
      <p style="font-weight: 400 !important;">Игрок имеет право и возможность вносить изменения в свои личные данные. Игрок можете сделать это в той степени, в которой это позволяет Веб-сайт, через свою учетную запись, или связавшись с нашей службой поддержки.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">9. Изменения политики конфиденциальности</h3>
      <p style="font-weight: 400 !important;">Все возможные изменения в правилах и условиях хранения личной информации, регулируются нашими общими Правилами и Условиями пользовательского соглашения. На этой странице Вы всегда сможете ознакомиться с последней версией этих положений.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">10. Контакты</h3>
      <p style="font-weight: 400 !important;">По всем вопросам, касающимся данного положения, Вы можете обратиться в нашу службу поддержки по электронной почте.</p>


       `,
  },
  {
    id: 1,
    pathname: "rules",
    headerId: "rules-header",
    contentId: "rules-content",
    label: "Правила и условия",
    labelKey: "footer.navigation.rule",
    text: `
    <h1 style="text-align: center; color: #F8F8F8; font-weight: 500 !important;">ПРАВИЛА И УСЛОВИЯ</h1>
      <p style="font-weight: 400 !important;">Перед принятием данного соглашения, пожалуйста, внимательно ознакомьтесь с его содержанием.</p>
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">1.1. Пользуясь услугами ENOMO, Вы соглашаетесь с следующим:</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Вы вступаете в юридически обязывающее соглашение с нами и принимаете наши Условия и Положения.</li>
          <li>Вы также соглашаетесь с остальными условиями, политиками и правилами, применяемыми к Вам и опубликованными на нашем Веб-сайте.</li>
      </ul>
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">1.2. Используя игровые услуги, Вы также подтверждаете следующее:</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Ваш возраст достигает минимального требования, установленного законодательством Вашего местопребывания для использования игровых услуг.</li>
          <li>Вы не находитесь на территориях с ограниченным доступом.</li>
          <li>Вам исполнилось 18 лет или более.</li>
          <li>Вы играете исключительно на свои собственные средства.</li>
          <li>Регистрация на нашем Сайте является Вашей первичной и единственной.</li>
          <li>В течение последних 12 месяцев Вы не пользовались процедурой самоисключения на других игровых Веб-сайтах.</li>
          <li>Вы ознакомились и принимаете все условия и положения.</li>
          <li>Вся предоставленная Вами информация компании ENOMO является верной и может быть проверена, а предоставление ложной информации может привести к аннулированию всех выигрышей.</li>
          <li>Вы осведомлены о том, что ENOMO устанавливает определенные ограничения на максимальный размер выигрыша и вывода средств за определенный период времени.</li>
      </ul>
      <p style="font-weight: 400 !important;">Мы ожидаем, что каждый из наших пользователей будет проявлять ответственность во время игры. Помните, что решение использовать наши услуги полностью зависит от Вас, и Вы делаете это на свой риск. Используя наши услуги, Вы осознаете риск потери денежных средств и несете за это полную ответственность. Вы также подтверждаете отсутствие претензий к нам или нашим партнерам в случае проигрышей.</p>
      <p style="font-weight: 400 !important;">Посещая наш Веб-сайт или открывая игровую учетную запись, Вы автоматически соглашаетесь со всеми условиями пользовательского соглашения, политики конфиденциальности, условиями рекламной деятельности, правилами ставок, бонусов и специальных предложений, доступных на Веб-сайте. Пожалуйста, ознакомьтесь с ними перед принятием решения. Если Вы не согласны с этими условиями, прекратите использовать наш Веб-сайт. В противном случае Ваше дальнейшее использование будет рассматриваться как согласие с указанными условиями.</p>

      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">ПРАВИЛА ОТКРЫТИЯ И ПОПОЛНЕНИЯ СЧЕТА</h2>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.1. Открытие учетной записи:</h3>
      <p style="font-weight: 400 !important;">Для доступа ко всем функциям нашего Сайта необходимо создать учетную запись (в дальнейшем именуемую как «Личный кабинет»). Для этого просто укажите Ваш адрес электронной почты или номер телефона, придумайте надежный пароль для входа и предоставьте необходимые личные данные, такие как Ваше имя, дата рождения и контактный номер телефона. Это поможет нам обеспечить Вашу безопасность и качественное обслуживание.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.2. Безопасный пароль:</h3>
      <p style="font-weight: 400 !important;">Игрок может изменить пароль в своем личном кабинете, после авторизации. Подбор безопасного пароля для регистрации важен для защиты Вашей учетной записи от несанкционированного доступа. Вот несколько рекомендаций для создания надежного пароля:</p>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Длина: Используйте пароль длиной не менее 8 символов. Чем длиннее пароль, тем сложнее его взломать.</li>
          <li>Сложность: Используйте комбинацию больших и маленьких букв, цифр и специальных символов. Избегайте использования легко угадываемых паролей, таких как "123456" или "password".</li>
          <li>Уникальность: Не используйте один и тот же пароль для нескольких сервисов. Каждый аккаунт должен иметь свой уникальный пароль.</li>
          <li>Избегание личной информации: Не используйте в пароле информацию, которую легко узнать о Вас (например, дату рождения, имена детей и т. д.).</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.3. Важное условие:</h3>
      <p style="font-weight: 400 !important;">Согласно нашим Условиям пользования, каждый пользователь имеет право на одну учетную запись на нашем Веб-сайте. Это означает, что Вы можете зарегистрировать только одну учетную запись на Игрока, используя один домашний адрес, IP-адрес, почтовый адрес, компьютер или другое устройство. Любые дополнительные учетные записи, созданные Вами на нашем Сайте, будут считаться дубликатами. Поэтому мы оставляем за собой право немедленно удалить все дублирующие учетные записи.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.4. Выбор метода оплаты:</h3>
      <p style="font-weight: 400 !important;">Игрок выбирает удобный метод оплаты для пополнения счета. Это может быть кредитная или дебетовая карта, банковский перевод, электронные кошельки или криптовалюты, в зависимости от доступных вариантов, предоставляемых Казино. Стоимость услуг по переводу денежных средств оплачивает Игрок. Для предотвращения спорных ситуаций храните квитанцию о переводе 3 года.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.5. Пополнение счета:</h3>
      <p style="font-weight: 400 !important;">Игрок осуществляет пополнение счета, следуя инструкциям на нашем Сайте и вводя необходимые данные о платеже.</p>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Минимальная сумма депозита: 200 RUB, 2 EUR, 2 USD, 85 UAH, 1 000 KZT, 7 BYN</li>
          <li>Минимальная сумма вывода средств: 1 500 RUB, 15 EUR, 15 USD, 600 UAH, 7 000 KZT, 50 BYN</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.6. Безопасность и конфиденциальность:</h3>
      <p style="font-weight: 400 !important;">Мы обеспечиваем безопасность и конфиденциальность финансовых транзакций, используя шифрование данных и другие меры защиты информации Игроков.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.7. Комиссия:</h3>
      <p style="font-weight: 400 !important;">Компания не отвечает за дополнительные комиссии и ограничения, установленные финансовыми посредниками Клиентов. Клиент может ознакомиться с дополнительными условиями и тарифами в соответствующих финансовых учреждениях.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.8. Важное уведомление:</h3>
      <p style="font-weight: 400 !important;">Если средства не поступают на игровой счет Клиента вовремя, рекомендуется немедленно обратиться в службу технической поддержки.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">2.9. Настройки уведомлений:</h3>
      <p style="font-weight: 400 !important;">При регистрации на нашем Веб-сайте пользователи автоматически соглашаются на получение сообщений, включая SMS и email-рассылки, а также другие рекламные уведомления. Мы используем информацию о наших Игроках для оповещения о наших акциях и специальных предложениях. Однако мы гарантируем конфиденциальность данных наших Клиентов и предоставляем им возможность отказаться от рекламных материалов, связавшись с нашей службой поддержки.</p>

      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">ПРАВИЛА НАЧИСЛЕНИЯ ВЫИГРЫША И СНЯТИЕ ДЕНЕГ СО СЧЕТА</h2>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">3.1. Начисление выигрыша</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Автоматическое начисление: Выигрыши в онлайн-Казино начисляются автоматически в соответствии с правилами каждой конкретной игры или ставки, сделанной Игроком.</li>
          <li>Минимальная сумма выигрыша: Для начисления выигрыша необходимо, чтобы сумма выигрыша достигла минимального установленного порога, который указан в правилах игры или нашем Сайте.</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">3.2. Снятие денег со счета</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Выбор метода вывода средств: Игрок может выбрать удобный метод для снятия денег со своего игрового счета. Мы предлагаем широкий выбор методов вывода средств, таких как банковский перевод, электронные кошельки и криптовалюты.</li>
          <li>Валюта: Операции пополнения счета и снятия денег должны производиться в одной валюте и в одной платежной системе.</li>
          <li>Подтверждение личности: Для обеспечения безопасности и защиты от мошенничества мы можем запросить подтверждение личности Игрока перед выполнением снятия средств. Это может включать предоставление скана удостоверения личности или других документов.</li>
          <li>Временные рамки: Запросы на снятие средств обрабатываются в кратчайшие сроки в соответствии с нашей политикой вывода средств. Обычно это занимает от нескольких часов до нескольких рабочих дней.</li>
          <li>Комиссии и ограничения: Все расходы по переводу денежных средств ложатся на получателя и удерживаются из суммы перевода, за исключением ситуаций, когда для вывода средств используется платежная система WebMoney.</li>
          <li>Ограничение метода вывода: Одним из ключевых аспектов нашего сервиса является ограничение метода вывода средств. Это означает, что при запросе на вывод средств с Вашего игрового счета средства будут отправлены только на ту же самую карту или платежный метод, который был использован для пополнения счета. Если у Вас изменились реквизиты карты, используемой для пополнения средств или вывода выигрышей с Вашего игрового счета, обязательно обратитесь в службу поддержки.</li>
          <li>Ограничение на сумму вывода: Уважаемые Игроки, пожалуйста, обратите внимание, что у нас существует ограничение на сумму вывода средств с игрового счета. Если запрошенная Вами сумма превышает установленный лимит, мы разделим выплату на несколько частей.</li>
          <li>Забота о Вашей безопасности: В случае подозрения в мошенничестве, мы придаем первостепенное значение Вашей безопасности и защите Ваших финансов. Для предотвращения дальнейших потенциальных убытков, мы можем временно ограничить или приостановить процесс вывода средств с Вашего игрового счета. Наша служба безопасности проведет дополнительные проверки и анализ всех сомнительных операций, связанных с Вашим аккаунтом.</li>
      </ul>
      <p style="font-weight: 400 !important;">Мы просим Вас сотрудничать с нами и предоставить любую дополнительную информацию или документы, которые могут помочь в разрешении ситуации. Игрок, не желающий пройти процедуру верификации или предоставляющий чужие, поддельные или отредактированные документы с использованием программ или графических редакторов, может столкнуться с аннулированием всех сделанных им ставок. Также временно может быть приостановлен доступ к его игровому счету и возможность вывода средств.</p>
      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">БОНУСНЫЕ ДЕНЬГИ</h2>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">4.1.</h3>
      <p style="font-weight: 400 !important;">В нашем Казино, ENOMO, мы предлагаем всем новым Игрокам постоянный бонус в размере 100% от первого депозита. Этот бонус доступен только при первом депозите Игрока и составляет 100% от суммы этого депозита. Бонусные средства – это награды, эквивалентные реальным деньгам, которые Казино предоставляет Игроку бесплатно. Они могут использоваться только внутри Казино, и вывод этих средств возможен только после выполнения условий отыгрыша x45. Отыграть бонус можно исключительно в игровых автоматах; использование бонусных средств в других играх приведет к аннулированию этих средств на счете Игрока. При запросе на вывод денежных средств неотыгранный бонус за первый депозит также будет аннулирован.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">4.2.</h3>
      <p style="font-weight: 400 !important;">В любое время Вы имеете возможность активировать бонус или отказаться от него, просто используя интерфейс в Вашем профиле Игрока.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">4.3. После активации бонуса:</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Сумма бонуса будет зачислена на Ваш бонусный счет и будет храниться отдельно от Вашего основного денежного счета.</li>
          <li>При размещении ставок средства будут списываться с Вашего основного денежного счета. Если на Вашем основном счете недостаточно средств, ставка будет списана с Вашего бонусного счета.</li>
          <li>Все выигрыши зачисляются на Ваш бонусный баланс и остаются недоступными для вывода до выполнения условий отыгрыша бонуса. Сам бонус также не может быть выведен до выполнения отыгрыша.</li>
          <li>После выполнения условий отыгрыша сумма средств на Вашем бонусном балансе, связанная с активным бонусом, переводится на Ваш денежный баланс и становится доступной для вывода.</li>
          <li>Неотыгранные бонусы не влияют на возможность вывода средств с Вашего денежного счета. Однако при выводе средств все активные неотыгранные бонусы на Вашем бонусном счете будут аннулированы.</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">4.4.</h3>
      <p style="font-weight: 400 !important;">Вы можете отказаться от бонуса в любое удобное для Вас время. При отмене бонуса все денежные средства, связанные с этим бонусом, будут аннулированы и не будут доступны для использования.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">4.5.</h3>
      <p style="font-weight: 400 !important;">При получении выигрыша за счет ставок, сделанных с использованием бонусных средств, выплата выигрыша ограничивается 10-кратным размером начисленного бонуса.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">4.6.</h3>
      <p style="font-weight: 400 !important;">Вы не можете выводить средства с бонусного счета. Когда Вы пополняете Ваш счет, эти средства добавляются к Вашему "денежному основному балансу". Вы можете выводить любые суммы с денежного баланса, однако это приведет к потере остатка средств на Вашем бонусном счету. Важно отметить, что подача заявки на вывод автоматически отменяет все активированные бонусы. Даже если Ваша заявка на вывод будет отклонена по какой-либо причине, баланс не будет восстановлен!</p>

      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">ПОЛИТИКА ВОЗВРАТА СРЕДСТВ</h2>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">5.1. Необратимость транзакций</h3>
      <p style="font-weight: 400 !important;">После того, как денежные средства, включая бонусы, были использованы в процессе игры, возврат денежных средств невозможен.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">5.2. Сроки обращения</h3>
      <p style="font-weight: 400 !important;">Запрос на возврат средств будет рассмотрен только если он поступил в течение первых 24 часов после предполагаемой транзакции, или в течение тридцати (30 дней), если Игрок утверждает, что его/ее учетной записью мог пользоваться другой человек или несовершеннолетний.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">5.3. Подтверждение личности</h3>
      <p style="font-weight: 400 !important;">Мы оставляем за собой право воздерживаться от возврата или обратной транзакции до тех пор, пока личность пользователя не будет надлежащим образом подтверждена. Для этого, Вы должны предоставить нотариально удостоверенные документы, подтверждающие Вашу личность. Если требуемые нотариально заверенные документы не будут предоставлены в течение 30 дней с момента запроса со стороны нашей компании, возврат средств или обратная транзакция не будут осуществлены. В таком случае Ваша учетная запись Игрока будет закрыта, и все средства на Вашем игровом счете будут аннулированы. Пожалуйста, имейте в виду, что данное решение окончательно, обязательно и не подлежит обжалованию.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">5.4. Обязательство к честной игре</h3>
      <p style="font-weight: 400 !important;">Игроки обязуются честно участвовать в играх и не использовать недопустимые способы влияния на исход игры, такие как компьютерные программы, математические уравнения и т.д.</p>


      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">НЕАКТИВНЫЕ СЧЕТА</h2>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">6.1. Определение неактивных счетов</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Согласно нашей политике, счет считается неактивным, если в течение года (12 месяцев) на нем не было совершено никаких игровых операций или успешных транзакций.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">6.2. Безопасность и управление данными</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Для обеспечения безопасности и эффективного управления данными, неактивные счета могут быть временно приостановлены или закрыты.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">6.3. Политика административной платы</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>В соответствии с нашими правилами и условиями обслуживания, обслуживание Вашего игрового счета является бесплатным пока Вы активно пользуетесь продуктами Казино. В случае, если Ваш игровой счет остается неактивным в течение установленного периода времени п.1, мы оставляем за собой право с положительного остатка на счету вычитаться административную плату за обслуживание неактивного счета. Если на Вашем игровом счету нулевой остаток, плата взиматься не будет.</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">6.4. Уведомление о плате</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">В случае применения первой административной платы с Вашего счета, мы отправим Вам уведомление о размере и условиях оплаты по электронной почте, которая была Вами предоставлена и была подтверждена. Плата будет взиматься ежемесячно до тех пор, пока на Вашем игровом счету больше не останется средств, или он снова не станет активным.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">6.5. Последствия неактивного счета</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>При первом списании платы за неактивный счет, одновременно с этим могут быть деактивированы все бонусы, накопленные деньги в акциях, программах лояльности и любые другие очки, которые были начислены на данный аккаунт.</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">6.6. Закрытие неактивного счета</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Казино оставляет за собой возможность закрыть неактивный счет и прекратить доступ к аккаунту, когда остаток на счете достигнет нуля. После закрытия счета, доступ к Вашему игровому аккаунту и все связанные с ним услуги и возможности будут полностью прекращены.</p>

      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">УВЕДОМЛЕНИЯ О РИСКАХ</h2>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">7.1.</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Принимая участие в играх на нашем Сайте, Вы осознаете, что существует риск потери средств, внесенных на Ваш счет.</p>
      <p style="margin-left: 20px; font-weight: 400 !important;">Замечаем, что в некоторых странах азартные игры онлайн могут быть незаконными. Пожалуйста, имейте в виду, что администрация нашего Сайта не может предоставить Вам юридические консультации или гарантировать законность использования наших услуг в Вашей юрисдикции.</p>
      <p style="margin-left: 20px; font-weight: 400 !important;">Компания не гарантирует, что наши услуги соответствуют требованиям законодательства Вашей страны. Используя наши услуги, Вы принимаете на себя ответственность за оценку законности их использования в Вашей юрисдикции. Пожалуйста, помните, что Ваши действия на нашем Сайте осуществляются на Ваш риск.</p>
      <p style="margin-left: 20px; font-weight: 400 !important;">Все Веб-сайты и игры предоставляются без явных или подразумеваемых гарантий.</p>

      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">КОММУНИКАЦИЯ СО СЛУЖБОЙ ПОДДЕРЖКИ</h2>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">8.1. Вежливость и уважение</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Игрок обязан общаться с нашей службой поддержки и с другими сотрудниками нашего Казино с уважением и вежливостью. Мы стремимся создать дружественную и конструктивную атмосферу для всех наших Клиентов.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">8.2. Четкость и ясность</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">При обращении за помощью, выражайте свои вопросы и проблемы четко и ясно. Это поможет нам быстрее и эффективнее решить Вашу проблему.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">8.3. Предоставление информации</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Для решения Вашей проблемы или ответа на вопрос нам может потребоваться дополнительная информация. Будьте готовы исполнить просьбы оператора, предоставить все необходимые данные и документы.</p>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">8.4. Соблюдение правил</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Воздерживайтесь от использования нецензурных или вульгарных выражений в ходе общения с операторами.</li>
          <li>Поддерживайте уважительное общение и не оскорбляйте оператора или унижайте его достоинство.</li>
          <li>Не высказывайте угрозы в отношении оператора или других сотрудников Казино.</li>
          <li>Обращайтесь к оператору службы поддержки только по вопросам, связанным с условиями соглашения о слотах или возможности его заключения и выполнения.</li>
          <li>Не злоупотребляйте правом на обращение к службе поддержки и не мешайте их нормальной работе (многократные обращения, спам, флуд и т.п.).</li>
      </ul>

      <p style="margin-left: 20px; font-weight: 400 !important;">Соблюдение этих правил поможет нам обеспечить качественное обслуживание и решить Вашу проблему с максимальной эффективностью. Благодарим за Ваше сотрудничество.</p>

      <h2 style="margin-top: 20px; color: #F8F8F8; font-weight: 500 !important;">ИЗМЕНЕНИЯ УСЛОВИЙ ПОЛЬЗОВАНИЯ</h2>

      <p style="margin-left: 20px; font-weight: 400 !important;">Компания оставляет за собой право вносить изменения, модифицировать или обновлять Условия пользования по ряду причин, включая коммерческие, юридические или для улучшения обслуживания Клиентов, без предварительного уведомления. Обновленные Условия пользования и дата их вступления в силу доступны на Веб-сайте. Пользователи несут ответственность за понимание и регулярную проверку всех обновлений.</p>

`,
  },
  {
    id: 2,
    pathname: "company",
    headerId: "company-header",
    contentId: "company-content",
    label: "О компании",
    labelKey: "footer.navigation.about",
    text: `
      <h1 style="text-align: center; color: #F8F8F8; font-weight: 500 !important;">О КОМПАНИИ</h1>
      <p style="font-weight: 400 !important;">Добро пожаловать в наше онлайн-казино — место, где азарт и развлечение сливаются воедино, чтобы подарить вам незабываемые эмоции и возможность выиграть крупные призы!</p>
    
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">Наша миссия</h3>
      <p style="font-weight: 400 !important;">Мы стремимся предоставить нашим игрокам высочайшее качество азартных игр и безупречный сервис. Наша главная цель — создать безопасное и увлекательное пространство, где каждый сможет испытать удачу и насладиться лучшими играми от ведущих мировых разработчиков.</p>
    
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">Почему выбирают нас</h3>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Широкий ассортимент игр: В нашем казино представлено более 500 классических слотов. Мы сотрудничаем с ведущими провайдерами, чтобы предложить вам самые популярные и новейшие игры на рынке.</li>
          <li>Безопасность и честность: Мы гарантируем безопасность ваших данных и честность всех игр благодаря использованию новейших технологий шифрования и сертифицированных генераторов случайных чисел.</li>
          <li>Круглосуточная поддержка: Наша дружелюбная команда поддержки всегда готова помочь вам в любое время дня и ночи. Мы ценим каждого нашего игрока и делаем все возможное, чтобы ваш опыт игры был максимально комфортным.</li>
          <li>Щедрые бонусы и акции: Мы предлагаем множество бонусов и акций, чтобы ваше время в нашем казино было еще более выгодным. От приветственных бонусов до еженедельных акций — у нас всегда есть что-то особенное для вас.</li>
      </ul>

      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">Наша история</h3>
      <p style="font-weight: 400 !important;">Наша компания была основана группой энтузиастов азартных игр с многолетним опытом в индустрии. С момента основания мы стремимся к совершенству во всем, что делаем. Наша цель — установить новые стандарты в мире онлайн-казино и предоставить нашим игрокам незабываемые впечатления.</p>
    
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">Ответственная игра</h3>
      <p style="font-weight: 400 !important;">Мы придерживаемся принципов ответственной игры и предлагаем нашим пользователям инструменты для контроля над игровым процессом. Мы поддерживаем создание безопасной и здоровой игровой среды, где каждый игрок может наслаждаться игрой без вреда для себя и окружающих.</p>
    
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">Присоединяйтесь к нам!</h3>
      <p style="font-weight: 400 !important;">Мы приглашаем вас стать частью нашей дружной игровой семьи. Зарегистрируйтесь сегодня и окунитесь в мир азартных игр с надежным и увлекательным онлайн-казино. Пусть удача всегда будет на вашей стороне!</p>
    
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">С уважением,</h3>
      <h3 style="color: #F8F8F8; font-weight: 500 !important;">Команда Enomo</h3>
    `,
  },
  // {
  //   id: 3,
  //   pathname: "partners",
  //   headerId: "partners-header",
  //   contentId: "partners-content",
  //   label: "Партнерская программа",
  //   labelKey: "footer.navigation.partners",
  //   text: "4444444444444444444444444",
  // },
  {
    id: 3,
    pathname: "gambling",
    headerId: "responsive-gambling-header",
    contentId: "responsive-gambling-content",
    label: "Ответственная игра",
    labelKey: "footer.navigation.gambling",
    text: `
     <h1 style="text-align: center; color: #F8F8F8; font-weight: 500 !important;">ОТВЕТСТВЕННАЯ ИГРА</h1>
      <p style="font-weight: 400 !important;">Важным аспектом нашего Казино является поддержка ответственной игры и сохранение контроля над азартным времяпрепровождением. Мы призываем всех наших Игроков играть разумно и осознанно.</p>
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">1.1. Сохранение контроля</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Мы призываем всех наших Игроков поддерживать контроль над своим азартным времяпрепровождением. Установите лимиты по времени и деньгам, которые Вы готовы потратить на игру, и придерживайтесь их строго. Это поможет Вам играть ответственно и избежать возможных финансовых проблем.</p>
      
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">1.2. Предупреждение игровой зависимости</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Азартные игры могут быть захватывающими, поэтому важно замечать признаки возможной игровой зависимости. Если Вы замечаете, что игра начинает занимать слишком много Вашего времени и внимания, обратитесь за помощью. Наши специалисты готовы предоставить Вам поддержку и советы по преодолению зависимости.</p>
      
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">1.3. Советы по управлению игрой</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Чтобы играть ответственно, следуйте простым советам:</p>
      <ul style="margin-left: 20px; font-weight: 400 !important;">
          <li>Устанавливайте лимиты по времени и деньгам. Определите сумму, которую Вы готовы потратить на игру, и строго придерживайтесь этого лимита. Также определите временные рамки для игры и не превышайте их.</li>
          <li>Не играйте на последние средства. Никогда не используйте последние деньги на игру. Играйте только теми средствами, которые Вы готовы потерять.</li>
          <li>Не играйте под влиянием алкоголя или наркотиков. Подобные вещества могут повлиять на Ваше решение и привести к неразумным ставкам.</li>
          <li>Поддерживайте баланс. Важно найти баланс между игрой и другими аспектами вашей жизни. Не позволяйте игре занимать все ваше время и внимание.</li>
          <li>Обращайтесь за помощью. Если у Вас возникают проблемы с азартной игрой, не стесняйтесь обратиться за помощью к специалистам по игромании или службе поддержки Казино.</li>
      </ul>
    
      
      <h3 style="margin-top: 10px; color: #F8F8F8; font-weight: 500 !important;">1.4. Самоисключение из игры</h3>
      <p style="margin-left: 20px; font-weight: 400 !important;">Если Вы чувствуете, что игровая активность начинает негативно влиять на Вашу жизнь, Вы можете воспользоваться возможностью самоисключения из игры. Обратитесь к нашей службе поддержки, и мы поможем Вам временно или постоянно исключить себя из доступа к играм в нашем Казино.</p>
      <p style="margin-left: 20px; font-weight: 400 !important;">Мы ценим каждого нашего Игрока и стремимся создать для Вас безопасную и приятную игровую среду. Помните, что ответственная игра - это ключ к долгосрочному удовлетворению от азартных развлечений.</p>

    
    `,
  },
];

interface IRules {}

const Rules: React.FC<IRules> = ({}) => {
  const isMobile = useMediaQuery({ maxWidth: 900 });
  const [expanded, setExpanded] = React.useState<string | false>("");
  const [tabValue, setTabValue] = React.useState(1);
  const { t } = useTranslation();
  const { name, email, phone, id }: IJivoSelector =
    useAppSelector(jivoSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [chatState, setChatState] = React.useState<
    "hidden" | "maximized" | "minimized"
  >("hidden");

  const widgetState = useWidgetState();
  const isWidgetReady = useWidgetIsReady();

  useEffect(() => {
    if (widgetState) {
      setChatState(widgetState.visibility);
    }
  }, [widgetState, chatState]);

  function handleNewEvent(event: EventHandlerPayload<"onNewEvent">) {
    //console.log("LiveChatWidget.onNewEvent", event);
  }

  const currentPathname = window.location.pathname.substring(1);
  useEffect(() => {
    const matchedAccordionItem = accordionData.find(
      (item) => item.pathname === currentPathname
    );
    if (matchedAccordionItem) {
      setExpanded(matchedAccordionItem.pathname);
      setTabValue(matchedAccordionItem.id);
    }
  }, [currentPathname]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    const matchedAccordionItem = accordionData.find(
      (item) => item.id === newValue
    );
    if (matchedAccordionItem) {
      navigate(`/${matchedAccordionItem.pathname}`);
    }
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);

      const matchedAccordionItem = accordionData.find(
        (item) => item.pathname === panel
      );
      if (matchedAccordionItem) {
        navigate(`/${matchedAccordionItem.pathname}`);
      }
    };
  // const isBig = useMediaQuery({ minWidth: 1599 });

  return !isMobile ? (
    <>
      <Container className="rules container" maxWidth={false} disableGutters>
        <Box sx={{ backgroundColor: "var(--secondary-background-color)" }}>
          <Box className="rules_navigation">
            {isWidgetReady && (
              <Box
                onClick={() => {
                  setChatState("maximized");
                }}
                className="rules_navigation_support"
              >
                <Box className="rules_navigation_title">Поддержка 24/7</Box>
                <Box className="rules_navigation_subtitle">
                  Свяжитесь с нами, если у вас остались вопросы
                </Box>
                <CardMedia
                  className="rules_navigation_img"
                  component="img"
                  alt={"title"}
                  image={strawberry}
                />
              </Box>
            )}
            <Box className="rules_navigation_menu">
              <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="Vertical tabs example"
                sx={{ borderRight: 1, borderColor: "divider" }}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
              >
                {accordionData.map((item, index) => (
                  <Tab
                    style={{ fontSize: "14px" }}
                    key={index}
                    label={t(item.labelKey)}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
        </Box>
        <Box className="rules-body" style={{ fontSize: "12px !important" }}>
          {accordionData.map((item, index) => (
            <TabPanel key={index} value={tabValue} index={index}>
              <Typography
                component={"span"}
                variant={"body2"}
                dangerouslySetInnerHTML={{ __html: item.text }}
              />
            </TabPanel>
          ))}
        </Box>
      </Container>
      <LiveChatWidget
        license="18252378"
        visibility={chatState}
        customerName={name || id || undefined}
        customerEmail={email || undefined}
        onNewEvent={handleNewEvent}
      />
      {/* <span>{widgetState?.availability}</span>
      <span>{widgetState?.visibility}</span> */}
    </>
  ) : (
    <>
      {accordionData.map((item) => (
        <Accordion
          className="accordion"
          key={item.id}
          expanded={expanded === item.pathname}
          onChange={handleChange(item.pathname)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="accordion_summary"
            aria-controls={item.contentId}
            id={item.headerId}
          >
            <Typography>{t(item.labelKey)}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordion_details">
            <Typography
              component={"span"}
              variant={"body2"}
              dangerouslySetInnerHTML={{ __html: item.text }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <LiveChatWidget
        license="18252378"
        visibility={chatState}
        customerName={name || id || undefined}
        customerEmail={email || undefined}
        onNewEvent={handleNewEvent}
      />
      {/* <span>{widgetState?.availability}</span>
      <span>{widgetState?.visibility}</span> */}
    </>
  );
};

export default Rules;
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
