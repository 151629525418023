import { useMemo } from "react";
import { createSelector } from "reselect";
import { withdrawal_payment_methods } from "../../../../constants/Constants";
import { RootState } from "../../../../store/rootStore";
// import { payments_methods } from "./Methods";

// Select the raw data from the Redux state
const UserWallets = (state: RootState) => state.user.wallets;
const Payments = (state: RootState) => state.payments;

// Create a memoized selector using reselect
export const profileWithdrawalSelector = createSelector(
  [UserWallets, Payments],
  (userWallet, payments) => {
    const activeWallet = userWallet.filter((wallet) => wallet.active == true);

    const methods = payments.paymentMethods.methods.filter(
      (method) =>
        method.currency === activeWallet[0].currency.name &&
        method.type === "withdrawals"
    );

    return {
      paymentMethods: methods,
    };
  }
);
