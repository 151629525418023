import { Box, Dialog, DialogContent } from "@mui/material";
import React, { useState } from "react";
import "./bonus.rules.css";
import { Image } from "../BonusImage/bonus.image";
import bonus_background from "../../../../assets/bonus/bonus_background.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CloseIcon from "@mui/icons-material/Close";
type IBonusRules = {
  active_bonus: boolean;
  open: boolean;
  title: string;
  min_dep: number;
  fs?: {
    count: number;
    game: string;
  };
  pers?: {
    count: number;
    max: number;
    min: number;
  };
  wager_fs: number;
  wager_pers: number;
  sub_img: {
    name: string;
    src: string;
  };
  duration: number;
  close: (
    e: React.MouseEvent<SVGSVGElement, MouseEvent> | {} | undefined
  ) => void;
  activate: () => void;
  playBonus: () => void;
};

export const BonusRules = ({
  active_bonus,
  open,
  close,
  title,
  sub_img,
  min_dep,
  fs,
  pers,
  wager_fs,
  wager_pers,
  activate,
  duration,
  playBonus,
}: IBonusRules) => {
  const [more, setMore] = useState(false);
  const moreHandler = () => {
    setMore(!more);
  };
  console.log(active_bonus);
  return (
    <Dialog
      open={open}
      onClose={close}
      className="game-dialog bonus-rules"
      sx={{ padding: "0" }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ width: "100%" }}>
        <Box className="bonus-rules__container">
          <CloseIcon className="bonus-rules__close" onClick={close} />
          <Box className="bonus-rules__main-content">
            <Image
              alt={sub_img.name}
              classname="bonus-rules__main-image"
              src={sub_img.src}
            />
            <Image
              alt={"background"}
              classname={"bonus-rules__main-background"}
              src={bonus_background}
            />
            <Box className="bonus-rules__main-title">{title}</Box>
            {active_bonus ? (
              <button
                className="bonus-card__bonus-rule-button"
                onClick={playBonus}
              >
                Играть
              </button>
            ) : (
              <button
                className="bonus-card__bonus-rule-button"
                onClick={activate}
              >
                Активировать
              </button>
            )}
          </Box>
          <Box className="bonus-rules__rules-content-container">
            <Box className="bonus-rules__rules-content-short">
              <Box className="bonus-rules__short-item-title">
                Для получения этого бонуса тебе необходимо:
              </Box>
              <Box className="bonus-rules__short-item">
                Быть авторизованным на сайте и нажать на кнопку "Активировать"
                или "Получить бонус"
              </Box>
              {!!min_dep && (
                <Box className="bonus-rules__short-item">
                  {`Внести минимальный депозит от ${min_dep} одним платежом.`}
                </Box>
              )}
              {fs && (
                <>
                  <Box className="bonus-rules__short-item">
                    {`Количество бесплатных спинов: ${fs.count}.`}
                  </Box>
                  <Box className="bonus-rules__short-item">
                    {`Открыть игру ${fs.game}.`}
                  </Box>
                </>
              )}
              {pers && (
                <>
                  <Box className="bonus-rules__short-item">
                    {`Сумма бонуса составляет: ${pers.count}% от твоего депозита.`}
                  </Box>
                  <Box className="bonus-rules__short-item">
                    {`Максимальная сумма бонуса: ${pers.max}.`}
                  </Box>
                  <Box className="bonus-rules__short-item">
                    {`Минимальная сумма бонуса ${pers.min}.`}
                  </Box>
                </>
              )}
              {!!fs && !!pers ? (
                <>
                  <Box className="bonus-rules__short-item">
                    {`Отыгрыш бонуса со спинов: ${wager_fs}х.`}
                  </Box>
                  <Box className="bonus-rules__short-item">
                    {`Отыгрыш бонуса к депозиту: ${wager_pers}х.`}
                  </Box>
                </>
              ) : fs ? (
                <Box className="bonus-rules__short-item">
                  {`Отыгрыш бонуса со спинов: ${wager_fs}х.`}
                </Box>
              ) : (
                <Box className="bonus-rules__short-item">
                  {`Отыгрыш бонуса к депозиту: ${wager_pers}х.`}
                </Box>
              )}
            </Box>
            <Box className="bonus-rules__rules-content-line" />
            <Box
              className={`bonus-rules__rules-content-rules ${more && "open"}`}
            >
              <button
                className="bonus-rules__rules-content-rules-button"
                onClick={moreHandler}
              >
                Правила и условия
                <ArrowForwardIosIcon />
              </button>
              <ol className="bonus-rules__rules-content-all">
                <li className="bonus-rules__rules-content-all-item">
                  Данное бонусное предложение доступно для клиентов Enomo
                  Casino.
                </li>
                {!!min_dep ? (
                  <li className="bonus-rules__rules-content-all-item">
                    {`Бонус активируется во время перевода на основной счет. Для получения бонуса нужно совершить пополнение на сумму не менее ${min_dep}.`}
                  </li>
                ) : (
                  <li className="bonus-rules__rules-content-all-item">
                    Бонус активируется после нажатия на кнопку "Активировать"
                    или "Получить бонус" в течение действия бонусаю
                  </li>
                )}
                {!!min_dep && (
                  <li className="bonus-rules__rules-content-all-item">
                    {`Если акционный депозит составил менее ${min_dep}, то бонус не начисляется.`}
                  </li>
                )}
                {fs && (
                  <>
                    <li className="bonus-rules__rules-content-all-item">{`Бесплатные спины можно использовать только в игре ${fs.game}.`}</li>
                    <li className="bonus-rules__rules-content-all-item">
                      Если клиент не использовал все бонусные спины по окончанию
                      бонусного предложения, то накопленная сумма бонусов
                      сгорает.
                    </li>
                    <li className="bonus-rules__rules-content-all-item">
                      Клиент может в любой момент проверить актуальную
                      информацию о количестве использованных бесплатных спинов
                      во вкладке «Мои Бонусы» в личном кабинете.
                    </li>
                    <li className="bonus-rules__rules-content-all-item">
                      После использования всех бонусных спинов, все выигранные
                      бонусные деньги нужно отыграть.
                    </li>
                  </>
                )}

                <li className="bonus-rules__rules-content-all-item">
                  {`Бонусное предложение действительно в 
                    течение ${duration} дней с момента активации.`}
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  {`Для того чтобы снять любую сумму денег, клиент должен отыграть
                  сумму бонуса не менее ${wager_fs} раз в течение ${duration} дней после
                  использования всех бонусных спинов, отыгрыш бонуса доступен
                  только в разделе "Слоты" в играх для отыгрыша.`}
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  При отыгрыше бонусов сначала списываются реальные деньги,
                  после чего бонусные.
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  Бонус доступен только для одного игрового счета на человека,
                  семью, квартиру, компьютер или IP-адрес. При подозрении на
                  нарушение путем множественных регистраций (поддельные счета,
                  игровые группы) Enomo Casino аннулирует бонус и причитающиеся
                  на него выигрыши. В случае регистрации второй учетной записи -
                  она будет удалена, а все игровые бонусы и выигрыши могут быть
                  аннулированы.
                </li>
                {!!min_dep && (
                  <li className="bonus-rules__rules-content-all-item">
                    Депозит, которым клиент пополняет счет для активации бонуса,
                    полностью резервируется системой до момента отыгрыша
                    (учитывая ситуацию, когда депозит был выше максимальной
                    суммы бонуса).
                  </li>
                )}
                <li className="bonus-rules__rules-content-all-item">
                  Казино оставляет за собой право просматривать записи
                  транзакций в любое время и по какой-либо причине. Если во
                  время такой проверки выяснится, что игрок применяет стратегии,
                  которые Казино по своему усмотрению считает злонамеренными,
                  Казино может отменить право такого игрока на дальнейшее
                  участие.
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  Данное предложение не суммируется с другими бонусами и
                  акциями.
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  Каждый клиент компании может воспользоваться бонусом один раз.
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  Enomo Casino оставляет за собой право в любое время изменить
                  или отменить это предложение.
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  Принимая бонус, клиент согласен с этими условиями, а также с
                  общими правилами и условиями Enomo Casino.
                </li>
                <li className="bonus-rules__rules-content-all-item">
                  Игрок имеет право отказаться от бонуса, если с момента его
                  начисления он не был использован и не было совершено ни одного
                  спина. Для списания бонуса следует обратиться в службу
                  поддержки или нажать на кнопку “Отменить бонус” в разделе “Мои
                  Бонусы” до начала использования бонуса.
                </li>
              </ol>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
