import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
// import { ProfilePersonalInfoSchema } from "../../../../utils/formValidations";
import { savePersonInfo } from "../../../../store/models/profile/profile.thunk";
import { getDatesArraysForSelect } from "../../../../utils/getDataForDatesSelectors";
import { ProfileInput } from "../../../input/input";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { ProfileSubmitButton } from "../../ProfileSubmitButton/ProfileSubmitButton";
import { MySelect } from "../../../select/select";
import "./ProfilePersonalInfo.css";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const ProfilePersonalInfo = () => {
  const { t } = useTranslation();
  const { dateArray, monthArray, yearArray } = getDatesArraysForSelect();
  const { user } = useAppSelector((state) => state);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const ProfilePersonalInfoSchema = Yup.object().shape({
    name: Yup.string()
      .required(t("main.profile.personalinfo.validation.name"))
      .min(2, t("main.profile.personalinfo.validation.minname"))
      .matches(
        /^[a-zA-Zа-яА-ЯёЁіІїЇ][a-zA-Zа-яА-ЯёЁіІїЇ'-` ]+[a-zA-Zа-яА-ЯёЁіІїЇ]?$/i,
        t("main.profile.personalinfo.validation.nameincorect")
      ),
    surname: Yup.string()
      .required(t("main.profile.personalinfo.validation.surname"))
      .min(2, t("main.profile.personalinfo.validation.minsurname"))
      .matches(
        /^[a-zA-Zа-яА-ЯёЁіІїЇ][a-zA-Zа-яА-ЯёЁіІїЇ'-` ]+[a-zA-Zа-яА-ЯёЁіІїЇ]?$/i,
        t("main.profile.personalinfo.validation.surnameincorect")
      ),
    secondname: Yup.string()
      .required(t("main.profile.personalinfo.validation.secondname"))
      .min(2, t("main.profile.personalinfo.validation.minsecondname"))
      .matches(
        /^[a-zA-Zа-яА-ЯёЁіІїЇ][a-zA-Zа-яА-ЯёЁіІїЇ'-` ]+[a-zA-Zа-яА-ЯёЁіІїЇ]?$/i,
        t("main.profile.personalinfo.validation.secondnameincorect")
      ),
    date: Yup.number().required(t("main.profile.personalinfo.validation.date")),
    month: Yup.string().required(
      t("main.profile.personalinfo.validation.month")
    ),
    year: Yup.number().required(t("main.profile.personalinfo.validation.year")),
    sex: Yup.string().required(t("main.profile.personalinfo.validation.sex")),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      surname: "",
      secondname: "",
      date: "",
      month: "",
      year: "",
      sex: "",
    },
    validationSchema: ProfilePersonalInfoSchema,
    onSubmit: async (values) => {
      // console.log({ values });

      const numDate = Date.parse(
        `${values.year}-${values.month}-${values.date}`
      );
      // console.log({ numDate });
      // console.log({ newDate: new Date(numDate).getDay() });
      setLoading(true);
      await dispatch(
        savePersonInfo({
          name: values.name,
          surname: values.surname,
          secondname: values.secondname,
          date: numDate + "",
          sex: values.sex,
        })
      );
      setLoading(false);
    },
  });
  useEffect(() => {
    formik.setValues({ ...formik.initialValues, id: user.id });
    if (user.personalInfo) {
      formik.setValues({
        id: user.id,
        name: user.personalInfo.name,
        surname: user.personalInfo.surname,
        secondname: user.personalInfo.secondname,
        date: new Date(+user.personalInfo.date).getDate().toString(),
        month: (new Date(+user.personalInfo.date).getMonth() + 1).toString(),
        year: new Date(+user.personalInfo.date).getFullYear().toString(),
        sex: user.personalInfo.sex,
      });
    }
  }, [user.personalInfo]);
  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: ".", text: t("main.profile.links.personalinfo") },
        {
          link: "contacts",
          text: t("main.profile.links.contacts"),
        },
        {
          link: "verification",
          text: t("main.profile.links.verification"),
        },
      ]}
    >
      <Box className="profile-personal-info">
        <form
          className="profile-personal-info__form"
          onSubmit={formik.handleSubmit}
        >
          <ProfileInput
            error={formik.errors.id}
            touched={formik.touched.id}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.id}
            name="id"
            label={t("main.profile.personalinfo.fields.id")}
            disabled={true}
          />
          <ProfileInput
            error={formik.errors.name}
            touched={formik.touched.name}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.name}
            name="name"
            label={t("main.profile.personalinfo.fields.name")}
            disabled={user.personalInfo ? true : false}
          />
          <ProfileInput
            error={formik.errors.surname}
            touched={formik.touched.surname}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.surname}
            name="surname"
            label={t("main.profile.personalinfo.fields.surname")}
            disabled={user.personalInfo ? true : false}
          />
          <ProfileInput
            error={formik.errors.secondname}
            touched={formik.touched.secondname}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.secondname}
            name="secondname"
            label={t("main.profile.personalinfo.fields.secondname")}
            disabled={user.personalInfo ? true : false}
          />
          <Box className="profile-personal-info__field">
            <Typography variant="h6" className="profile-personal-info__label">
              {t("main.profile.personalinfo.fields.birthday")}
            </Typography>
            <Box className="profile-personal-info__birth">
              <MySelect
                className="date"
                dataArray={dateArray}
                error={formik.errors.date}
                touched={formik.touched.date}
                name="date"
                value={formik.values.date}
                onChange={formik.handleChange}
                disabled={user.personalInfo ? true : false}
              />
              <MySelect
                className="month"
                dataArray={monthArray.map((month) => {
                  return {
                    value: month.value,
                    text: t(`months.${month.text}`),
                  };
                })}
                error={formik.errors.month}
                touched={formik.touched.month}
                name="month"
                value={formik.values.month}
                onChange={formik.handleChange}
                disabled={user.personalInfo ? true : false}
              />
              <MySelect
                className="year"
                dataArray={yearArray}
                error={formik.errors.year}
                touched={formik.touched.year}
                name="year"
                value={formik.values.year}
                onChange={formik.handleChange}
                disabled={user.personalInfo ? true : false}
              />
            </Box>
          </Box>
          <Box className="profile-personal-info__field">
            <Typography variant="h6" className="profile-personal-info__label">
              {t("main.profile.personalinfo.fields.sex")}
            </Typography>
            <MySelect
              className="sex"
              dataArray={[
                {
                  value: "male",
                  text: t("main.profile.personalinfo.fields.male"),
                },
                {
                  value: "female",
                  text: t("main.profile.personalinfo.fields.female"),
                },
              ]}
              error={formik.errors.sex}
              touched={formik.touched.sex}
              name="sex"
              value={formik.values.sex}
              onChange={formik.handleChange}
              disabled={user.personalInfo ? true : false}
            />
          </Box>
          <ProfileSubmitButton
            text={t("buttons.save")}
            disabled={loading || !!user.personalInfo || !formik.isValid}
            loading={loading}
          />
        </form>
      </Box>
    </ProfileContentContainer>
  );
};
