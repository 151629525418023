import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { fetchAvailableBonuses } from "../../../../store/models/bonuses/bonuses.thunk";
import BonusesListItem from "../../../Bonuses/BonusesItem/BonusesListItem";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import "./ProfileAvailableBonuses.css";
import { availableBonusesListSelector } from "./ProfileAvailableBonuses.selector";
import { useMediaQuery } from "react-responsive";
import { Loader } from "../../../loader/loader";
import { BonusRulesFetched } from "../../../Bonuses/BonusesItem/BonusRulesFetched/bonus.rules.fetched";

const ProfileAvailableBonuses = () => {
  const dispatch = useAppDispatch();
  const { availableBonus } = useAppSelector(availableBonusesListSelector);
  const [loading, setLoading] = useState(true);
  // console.log("availableBonus===", availableBonus);
  // const isMounted = useRef(false);
  const isMobile = useMediaQuery({ maxWidth: 999 });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(fetchAvailableBonuses({ mobile: isMobile }));
      setLoading(false);
    };
    fetchData();
  }, [dispatch]);

  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: "..", text: "АКТИВНЫЕ" },
        { link: "", text: "ДОСТУПНЫЕ" },
        { link: "../history", text: "ИСТОРИЯ" },
      ]}
    >
      <Box className="available-bonuses">
        <Box className="available-bonuses__container">
          {loading ? (
            <Loader />
          ) : availableBonus.length > 0 ? (
            <Box className="active-bonuses__bonuses-list">
              {availableBonus.map((bonus, index) => (
                <BonusesListItem
                  {...bonus}
                  bg_img={
                    isMobile
                      ? bonus.images.mobile.bg_image
                      : bonus.images.desktop.bg_image
                  }
                  sub_img={
                    isMobile
                      ? bonus.images.mobile.sub_image
                      : bonus.images.desktop.sub_image
                  }
                  customClassName={"active-bonuses__item"}
                />
              ))}
            </Box>
          ) : (
            <Box
              className="no-bonuses"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h6" color="textPrimary">
                У вас еще нет доступных бонусов
              </Typography>
              <Link to="/bonuses" className="no-bonuses__link">
                Перейти к бонусам
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </ProfileContentContainer>
  );
};

export default ProfileAvailableBonuses;
