import React, { useCallback } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import MButton from "../../Button/Button";
import "./SlotCard.css";
import { useAppSelector } from "../../../store/hooks/store.hooks";
import { useDispatch } from "react-redux";
import {
  setAuthorizationModal,
  setRedirect,
} from "../../../store/models/auth/auth.slice";
import { useTranslation } from "react-i18next";
import { setDemo } from "../../../store/models/slots/slot.slice";

interface ISlotCard {
  src: string;
  title: string;
  url: string;
  cardId: number;
  active: number | null;
  setActive: React.Dispatch<React.SetStateAction<number | null>>;
  type?: "big" | "wide";
}

export const SlotCard = ({
  src,
  title,
  url,
  cardId,
  active,
  setActive,
  type,
}: ISlotCard) => {
  const { t } = useTranslation();
  const auth = useAppSelector((state) => state.authorization.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeHandler = (id: number | null) => {
    setActive(id);
  };
  return (
    <Card
      className={`slot-card ${active === cardId ? "active" : ""} ${type || ""}`}
      onMouseOver={(e) => {
        activeHandler(cardId);
      }}
      onMouseOut={(e) => {
        activeHandler(null);
      }}
      onClick={(e) => {
        e.stopPropagation();
        activeHandler(cardId);
      }}
      sx={{ cursor: "pointer" }}
    >
      <Box style={{ position: "relative", height: "100%" }}>
        <CardMedia
          component="img"
          alt={title}
          image={`/api/slots/slots/image/${src}`}
        />
        <CardContent>
          <Typography variant="body1" className="slot-card__title">
            {title}
          </Typography>
          <CardActions
            className={`hovercard ${active === cardId ? "active" : ""}`}
          >
            <Button
              className="Button Button-secondary slot-card__primary-button pulse-button primary-hover"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(setDemo(false));
                if (auth) {
                  navigate(`/slots/${url}`);
                } else {
                  dispatch(setAuthorizationModal(true));
                  dispatch(setRedirect(`/slots/${url}`));
                }
              }}
            >
              {t("slots.play")}
            </Button>
            <MButton
              styling="primary"
              onClick={() => {
                // debugger;
                dispatch(setDemo(true));
                navigate(`/slots/${url}`);
              }}
              text={t("slots.demo")}
            />
          </CardActions>
        </CardContent>
      </Box>
    </Card>
  );
};
