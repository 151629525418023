import { createSlice } from "@reduxjs/toolkit";
import reducers from "./user.reducers";
import { IUser } from "./typing/user.interface";

export const initialStateUser: IUser = {
  id: "",
  contacts: {
    email: "",
    emailConfirmation: false,
    phone: "",
    phoneConfirmation: false,
  },
  verification: "",
  verificationStage: "expectation",
  personalInfo: {
    name: "",
    surname: "",
    secondname: "",
    date: "",
    sex: "",
  },
  nickname: "",
  currencies: [],
  wallets: [
    {
      active: false,
      balance: {
        id: 0,
        main_balance: "",
        bonus_balance: "",
        reserved_balance: "",
      },
      currency: {
        id: 0,
        name: "RUB",
        default: true,
      },
      id: 0,
    },
  ],
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialStateUser,
  reducers,
});

// Action creators are generated for each case reducer function
export const { setUser, changeActiveWallet, updateVerification } =
  userSlice.actions;

export default userSlice.reducer;
