import { memo, useEffect } from "react";
import {
  Navigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Loader } from "../components/loader/loader";
import { useAppDispatch, useAppSelector } from "../store/hooks/store.hooks";
import {
  setAuthorizationModal,
  setRedirect,
} from "../store/models/auth/auth.slice";

interface PropType {
  component: React.FC;
  redirect?: boolean;
}

const ProtectedRoute: React.FC<PropType> = memo(
  ({ component: Component, redirect }) => {
    const [auth, isVerifyed] = useAppSelector((state) => [
      state.authorization.auth,
      state.authorization.isVerifyed,
    ]);
    const location = useLocation();
    const dispatch = useAppDispatch();

    if (isVerifyed) {
      if (auth) return <Component />;
      dispatch(setAuthorizationModal(true));
      if (redirect) dispatch(setRedirect(location.pathname));
      return <Navigate to="/" />;
    } else {
      return <Loader />;
    }
  }
);

export default ProtectedRoute;
