import i18n from "../i18n";

export function getDatesArraysForSelect() {
  const date = new Date();
  const dateArray = [];
  const yearArray = [];
  for (let i = 1; i < 32; i += 1) {
    dateArray.push({ value: i, text: i });
  }
  for (let i = date.getFullYear() - 18; i > date.getFullYear() - 100; i -= 1) {
    yearArray.push({ value: i, text: i });
  }
  const monthArray = [
    { value: 1, text: "January" },
    { value: 2, text: "February" },
    { value: 3, text: "March" },
    { value: 4, text: "April" },
    { value: 5, text: "May" },
    { value: 6, text: "June" },
    { value: 7, text: "July" },
    { value: 8, text: "August" },
    { value: 9, text: "September" },
    { value: 10, text: "October" },
    { value: 11, text: "November" },
    { value: 12, text: "December" },
  ];
  return { dateArray, monthArray, yearArray };
}

export const getCurrentDate = (date: string) => {
  const d = new Date(+date);

  let day = d.getDate();
  let month = d.getMonth();
  let year = d.getFullYear();
  let hour = d.getHours();
  let min = d.getMinutes();

  month = month + 1;
  if (day < 10) {
    //@ts-ignore
    day = "0" + day;
  }

  if (month < 10) {
    //@ts-ignore
    month = `0${month}`;
  }
  if (hour < 10) {
    //@ts-ignore
    hour = `0${hour}`;
  }
  if (min < 10) {
    //@ts-ignore
    min = `0${min}`;
  }

  let format1 = `${day}/${month}/${year} ${hour}:${min}`;

  return format1;
};
