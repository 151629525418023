import { IUser } from "../../store/models/user/typing/user.interface";
import { initialStateUser } from "../../store/models/user/user.slice";
import { IUserService } from "./typing/user.interface";
import {
  TGetUserActiveCurrency,
  TGetUserActiveWallet,
  TGetUserName,
  TGetUserSurname,
  TGetUserSummaryBalance,
  TGetUserId,
} from "./typing/user.type";

export class UserService implements IUserService {
  private user: IUser;

  constructor(user: IUser = initialStateUser) {
    this.user = user;
  }

  public getUserActiveWallet: TGetUserActiveWallet = () => {
    return this.user.wallets.find((w) => w.active) || this.user.wallets[0];
  };
  public getUserActiveCurrency: TGetUserActiveCurrency = () => {
    return this.getUserActiveWallet()?.currency?.name;
  };
  public getUserSummaryBalance: TGetUserSummaryBalance = () => {
    const wallet = this.getUserActiveWallet();
    const summaryBalance = (
      +wallet.balance.main_balance +
      +wallet.balance.bonus_balance +
      +wallet.balance.reserved_balance
    ).toFixed(2);
    return summaryBalance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
  public getUserName: TGetUserName = () => {
    return this.user.personalInfo?.name;
  };
  public getUserSurname: TGetUserSurname = () => {
    return this.user.personalInfo?.surname;
  };
  public getUserID: TGetUserId = () => {
    return this.user.id;
  };
}
