import { UserService } from "./../../services/user/user.service";
import { currencies } from "../../constants/Constants";
import { TSelector } from "../../store/typing/types";
import { IWalletsSelector } from "./typing/Wallets.interface";

export const WalletsSelector: TSelector<IWalletsSelector> = ({
  user: { wallets, currencies },
}) => {
  const userWallets = wallets.map((wallet) => ({
    ...wallet,
    linked: true,
  }));

  const additionalCurrencies = currencies
    .filter(
      (currency) =>
        !userWallets.some((wallet) => wallet.currency.id === currency.id)
    )
    .map((currency) => ({
      active: false,
      balance: {
        bonus_balance: "0.00000",
        id: 0,
        main_balance: "0.00000",
        reserved_balance: "0.00000",
      },
      currency: currency,
      id: -1,
      linked: false,
    }));

  const combinedWallets = [...userWallets, ...additionalCurrencies];

  return { wallets: combinedWallets };
};
