import {
  ICurrency,
  IID,
  IName,
  ISurname,
  IUserBalance,
} from "../../../store/models/user/typing/user.interface";
import { UserService } from "../../../services/user/user.service";
import { RootState } from "../../../store/rootStore";
import { createSelector } from "reselect";
interface IProfileMenuSelector {
  summaryBalance: string;
  userBalance: IUserBalance;
  currency: ICurrency["name"];
  name: IName;
  surname: ISurname;
  id: IID;
}

const selectRawData = (state: RootState) => state.user;

// Create a memoized selector using reselect
export const ProfileMenuSelector = createSelector(selectRawData, (user) => {
  const currentUser = new UserService(user);
  const wallet = currentUser.getUserActiveWallet();
  const currency = currentUser.getUserActiveCurrency();
  const name = currentUser.getUserName();
  const surname = currentUser.getUserSurname();
  const id = currentUser.getUserID();

  const summaryBalance = currentUser.getUserSummaryBalance();

  return {
    summaryBalance,
    userBalance: wallet.balance,
    currency,
    name,
    surname,
    id,
  } as IProfileMenuSelector;
});
