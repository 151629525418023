type IImageProps = {
  alt: string;
  classname: string;
  src: string;
};

export const Image = ({ alt, classname, src }: IImageProps) => {
  return (
    <>
      <img src={src} className={classname} alt={alt} />
    </>
  );
};
