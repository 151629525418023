import React from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { ISelectProps } from "./typing/types";
import "./select.css";

export const MySelect = ({
  className,
  dataArray,
  error,
  touched,
  onChange,
  name,
  value,
  disabled,
}: ISelectProps) => {
  return (
    <Box className={`profile-select ${className ? className : ""}`}>
      <FormControl
        sx={{ width: "100%" }}
        error={Boolean(error && touched)}
        disabled={disabled}
      >
        <Select
          id={name}
          value={value}
          onChange={onChange}
          autoWidth
          name={name}
        >
          {dataArray.map((data) => {
            return (
              <MenuItem key={data.value} value={data.value}>
                {data.text}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {error && touched ? (
        <Typography className="Typography-error" component="p" variant="body2">
          {error}
        </Typography>
      ) : null}
    </Box>
  );
};
