import React, { useEffect } from "react";
import { useAppDispatch } from "../../store/hooks/store.hooks";
// import "./Payments.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Container, Typography } from "@mui/material";
import { successfulFreekassa } from "../../store/models/payment/payment.thunk";
import { Loader } from "../../components/loader/loader";
import Main from "../Main/main";
import { setAuthorizationModal } from "../../store/models/auth/auth.slice";

interface ILogin {}

const Login: React.FC<ILogin> = ({}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAuthorizationModal(true));
  }, [dispatch]);
  return (
    <React.Fragment>
      <Main />
    </React.Fragment>
  );
};

export default Login;
