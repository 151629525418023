import "./mainSlider.css";
import MButton from "../Button/Button";
import { useState } from "react";
import Slider from "react-slick";

import {
  Box,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/hooks/store.hooks";
import {
  setRedirect,
  setRegistrationModal,
} from "../../store/models/auth/auth.slice";
import { useNavigate } from "react-router-dom";
import { bonusesListSelector } from "../Bonuses/Bonuses.selector";
import { IBonus } from "../../store/models/bonuses/typing/bonuses.interface";
import { useTranslation } from "react-i18next";
import { FooterPayments } from "../Footer/FooterPayments/FooterPayments";
import { useMediaQuery } from "react-responsive";
import { IBonusesListSelector } from "../Bonuses/typing/BonusesList.interface";
import { createSelector } from "@reduxjs/toolkit";
import { TSelector } from "../../store/typing/types";
import { RootState } from "../../store/rootStore";

const selectBonuses = (state: RootState) => state.bonuses;

export const bonusesSlideSelector: TSelector<IBonusesListSelector> =
  createSelector(
    selectBonuses,
    ({ bonuses }) =>
      ({
        bonuses: bonuses.filter((item) => {
          return item.type === "all" && item.placement === "banner";
        }),
      } as IBonusesListSelector)
  );

export const MainSlider = () => {
  const { bonuses } = useAppSelector(bonusesSlideSelector);
  const dispatch = useAppDispatch();
  // useEffect(() => {
  //   if (!bonuses.length) dispatch(fetchBonuses());
  // }, []);
  return (
    <>
      <Box
        sx={{ display: { xs: "none", sm: "block" } }}
        style={{
          padding: "0px",
          marginBottom: "56px",
          position: "relative",
          // minHeight: "500px",
        }}
      >
        <Slider
          adaptiveHeight={true}
          dots={true}
          dotsClass="slide-dots"
          infinite={true}
          fade={true}
          speed={1000}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={5000}
          arrows={false}
        >
          {bonuses.map((item, i) => (
            <ItemDesc key={i} item={item} />
          ))}
        </Slider>
      </Box>
      <Container sx={{ display: { xs: "block", sm: "none" } }}>
        <SlideCard
          slider={true}
          className="card-content"
          sx={{
            display: { sm: "block" },
            padding: { md: "32px", sm: "7px" },
          }}
        />
      </Container>
    </>
  );
};

const ItemDesc: React.FC<{ item: IBonus }> = ({
  item: {
    title,
    sub_title,
    images: {
      desktop: { bg_image, sub_image },
    },
  },
}) => {
  const isBig = useMediaQuery({ minWidth: 1599 });
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: `url(${bg_image.src})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        objectFit: "cover",
        width: "100%",
        height: "calc(50vw - 100px)",
        maxHeight: "500px",
        position: "relative",
        borderRadius: "0px",
      }}
    >
      <Container
        sx={{
          position: "relative",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
        maxWidth={isBig ? "xl" : "lg"}
      >
        <Typography
          className="slide-title"
          alignItems="center"
          align="center"
          component="h6"
        >
          {title}
        </Typography>
        <Typography
          className="slide-subtitle"
          alignItems="center"
          align="center"
          component="p"
          variant="body2"
          maxWidth={400}
        >
          {sub_title}
        </Typography>
        <Box
          className="slide-subimg"
          component="img"
          alt={title}
          src={sub_image.src}
          sx={{ objectFit: "cover" }}
        />
        <SlideCard
          sx={{
            padding: { md: "32px", sm: "7px" },
          }}
          className="slide-box desc"
          slider={false}
        />
      </Container>
    </Paper>
  );
};
const ItemMobile: React.FC<{ item: IBonus }> = ({
  item: {
    title,
    sub_title,
    images: {
      mobile: { bg_image, sub_image },
    },
  },
}) => {
  return (
    <Paper
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        objectFit: "cover",
        width: "100%",
        maxHeight: "130px",
        minHeight: "130px",
        position: "relative",
        alignItems: "center",
        borderRadius: "0px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          opacity: 0.7,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundImage: `url(${bg_image.src})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Box>
      <Typography
        className="slide-title-mob"
        alignItems="center"
        align="center"
        component="h6"
      >
        {title}
      </Typography>
      <Typography
        className="slide-subtitle-mob"
        alignItems="center"
        align="center"
        component="p"
        variant="body2"
      >
        {sub_title}
      </Typography>
      <Box
        className="slide-subimg-mob"
        component="img"
        alt={title}
        src={sub_image.src}
      />
    </Paper>
  );
};
const SlideCard = ({ slider, ...props }: any) => {
  const { t, i18n } = useTranslation();
  const { bonuses } = useAppSelector(bonusesSlideSelector);
  const [openPromocode, setOpenPromocode] = useState(true);
  const [promocode, setPromocode] = useState("");
  const [amount, setAmount] = useState("");
  // const [currencyValue, setCurrencyValue] = useState("");
  const navigate = useNavigate();
  const [auth, email] = useAppSelector((state) => [
    state.authorization.auth,
    state.user.contacts.email,
  ]);
  const dispatch = useAppDispatch();

  const handleClickOpen = () => {
    sessionStorage.setItem("enprsl", promocode);
    sessionStorage.setItem("enamsl", amount);

    if (auth) {
      navigate("/profile/payments");
    } else {
      dispatch(setRegistrationModal(true));
      dispatch(setRedirect("/profile/payments"));
    }
  };
  return (
    <Card
      variant="outlined"
      {...props}
      style={{
        borderRadius: "var(--primary-border-radius)",
      }}
    >
      {slider && (
        <Slider
          dots={true}
          dotsClass="slide-dots"
          infinite={true}
          speed={1000}
          slidesToShow={1}
          slidesToScroll={1}
          autoplay={true}
          autoplaySpeed={5000}
          arrows={false}
        >
          {bonuses.map((item, i) => (
            <ItemMobile key={i} item={item} />
          ))}
        </Slider>
      )}
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            maxWidth: "313px",
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
          >
            <FormControl
              sx={{ m: 1 }}
              fullWidth
              style={{ margin: "0 0 10px 0" }}
              className="primary-text-color"
            >
              <OutlinedInput
                className="primary-text-color"
                type="number"
                inputProps={{ min: 0 }}
                sx={{ border: "none" }}
                id="currency"
                size="small"
                margin="dense"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                endAdornment={
                  <InputAdornment position="end" className="primary-text-color">
                    ₽
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
          <MButton
            text={t("buttons.startgame")}
            styling="secondary"
            className="primary-hover "
            onClick={handleClickOpen}
            style={{}}
          />
          <FooterPayments slider={true} />
          {openPromocode ? (
            <TextField
              //   error={Boolean(errors.registrationPromocode)}
              size="small"
              margin="dense"
              fullWidth
              id="promocode"
              placeholder={t("registration.fields.promocode")}
              value={promocode.toUpperCase().replace(/\s/g, "")}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPromocode(event.target.value);
              }}
              name="promocode"
              autoComplete="off"
            />
          ) : (
            <div
              className="secondary-text-color"
              style={{ cursor: "pointer", fontSize: "14px" }}
              onClick={() => setOpenPromocode(true)}
            >
              {t("registration.fields.confirmPromocode")}
            </div>
          )}
        </CardContent>
      </Box>
    </Card>
  );
};
