import React from "react";
import { Footer } from "../../Footer/Footer";
import "./SlotsBlocked.css";
import { Box, Typography } from "@mui/material";
import Button from "../../Button/Button";
import { useNavigate } from "react-router-dom";

type ISlotsBlockedProps = {
  title: string;
  text: string;
};

export const SlotsBlocked = ({ title, text }: ISlotsBlockedProps) => {
  const navigate = useNavigate();
  const toMainHandler = () => {
    navigate("/");
  };
  return (
    <>
      <Box className="slots-blocked">
        <Box className="slots-blocked__container">
          <Box className="slots-blocked__box">
            <Typography variant="h3" className="slots-blocked__title">
              {title}
            </Typography>
            <Typography variant="h6" className="slots-blocked__text">
              {text}
            </Typography>
          </Box>
          <Button
            text="главная"
            styling="secondary"
            onClick={toMainHandler}
            className={"slots-blocked__button"}
          />
        </Box>
      </Box>
      <Footer />
    </>
  );
};
