import React from "react";
import RequestPassword from "../../../pages/Resetpassword/RequestPassword";
import ResetPassword from "../../resetpassword/ResetPassword";
import { Wallets } from "../../wallets/Wallets";
import { ProfileContentContainer } from "../ProfileContentContainer/ProfileContentContainer";

const ProfileSecurity = () => {
  return (
    <React.Fragment>
      <ProfileContentContainer
        links={[
          { link: "/profile", text: "" },
          { link: "", text: "ПАРОЛЬ" },
        ]}
      >
        <RequestPassword />
      </ProfileContentContainer>
    </React.Fragment>
  );
};

export default ProfileSecurity;
