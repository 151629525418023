import { UserService } from "./../../services/user/user.service";
import { IMenuappbarSelector } from "./typing/Menuappbar.interface";
import { RootState } from "../../store/rootStore";
import { createSelector } from "reselect";

const selectRawData = (state: RootState) => ({
  user: state.user,
  auth: state.authorization.auth,
  isVerifyed: state.authorization.isVerifyed,
});

export const MenuappbarSelector = createSelector(
  selectRawData,
  ({ user, auth, isVerifyed }) => {
    const currentUser = new UserService(user);

    const currency = currentUser.getUserActiveCurrency();
    const summaryBalance = currentUser.getUserSummaryBalance();

    return {
      userBalance: summaryBalance,
      userCurrency: currency,
      auth,
      isVerifyed,
    } as IMenuappbarSelector;
  }
);
