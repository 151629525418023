import * as Yup from "yup";
import i18n from "../i18n";

// export const SignupSchema = Yup.object().shape({
//   registrationPassword: Yup.string()
//     .min(6, i18n.t("validationschema.min"))
//     .required(i18n.t("validationschema.minRequired"))
//     .matches(/^(?=.*[a-z])/g, i18n.t("validationschema.matches1lowerCase"))
//     .matches(/^(?=.*[A-Z])/g, i18n.t("validationschema.matches1upperCase"))
//     .matches(/^(?=.*[0-9])/g, i18n.t("validationschema.matches1number")),
//   registrationEmail: Yup.string()
//     .matches(/^(?=.*[A-Za-z])/g, i18n.t("validationschema.onlyEn"))
//     .email(i18n.t("validationschema.email"))
//     .required(i18n.t("validationschema.emailRequired"))
//     .matches(
//       /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
//       i18n.t("validationschema.email")
//     ),
//   registrationSwitch: Yup.bool().oneOf(
//     [true],
//     i18n.t("validationschema.confirmation")
//   ),
// });

// export const SigninSchema = Yup.object().shape({
//   loginPassword: Yup.string()
//     // .min(6, i18n.t("validationschema.min"))
//     .min(6, i18n.t("test"))
//     .required(i18n.t("validationschema.minRequired"))
//     .matches(/^(?=.*[a-z])/g, i18n.t("validationschema.matches1lowerCase"))
//     .matches(/^(?=.*[A-Z])/g, i18n.t("validationschema.matches1upperCase"))
//     .matches(/^(?=.*[0-9])/g, i18n.t("validationschema.matches1number"))
//     .matches(/^(?=.*[A-Za-z])/g, i18n.t("validationschema.onlyEn")),
//   loginEmail: Yup.string()
//     .matches(/^(?=.*[A-Za-z])/g, i18n.t("validationschema.onlyEn"))
//     .email(i18n.t("validationschema.email"))
//     .required(i18n.t("validationschema.emailRequired"))
//     .matches(
//       /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
//       i18n.t("validationschema.email")
//     ),
// });
// export const resetPasswordSchema = Yup.object().shape({
//   resetEmail: Yup.string()
//     .matches(/^(?=.*[A-Za-z])/g, i18n.t("validationschema.onlyEn"))
//     .email(i18n.t("validationschema.email"))
//     .required(i18n.t("validationschema.email"))
//     .matches(
//       /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
//       i18n.t("validationschema.email")
//     ),
// });

export const RequestPasswordAuthSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, "Введите мин. 6 символов")
    .required("Введите мин. 6 символов")
    .matches(/^(?=.*[a-z])/g, "Введите маленькую букву")
    .matches(/^(?=.*[A-Z])/g, "Введите заглавную (большую) букву")
    .matches(/^(?=.*[0-9])/g, "Введите мин. 1 цифру"),
  password: Yup.string()
    .min(6, "Введите мин. 6 символов")
    .required("Введите мин. 6 символов")
    .matches(/^(?=.*[a-z])/g, "Введите маленькую букву")
    .matches(/^(?=.*[A-Z])/g, "Введите заглавную (большую) букву")
    .matches(/^(?=.*[0-9])/g, "Введите мин. 1 цифру"),
  confirmPassword: Yup.string()
    .required("Введите мин. 6 символов")
    .matches(/^(?=.*[a-z])/g, "Введите маленькую букву")
    .matches(/^(?=.*[A-Z])/g, "Введите заглавную (большую) букву")
    .matches(/^(?=.*[0-9])/g, "Введите мин. 1 цифру")
    .oneOf([Yup.ref("password")], "Пароли не совпадают"),
});
export const RequestPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Введите мин. 6 символов")
    .required("Введите мин. 6 символов")
    .matches(/^(?=.*[a-z])/g, "Введите маленькую букву")
    .matches(/^(?=.*[A-Z])/g, "Введите заглавную (большую) букву")
    .matches(/^(?=.*[0-9])/g, "Введите мин. 1 цифру"),
  confirmPassword: Yup.string()
    .required("Введите мин. 6 символов")
    .matches(/^(?=.*[a-z])/g, "Введите маленькую букву")
    .matches(/^(?=.*[A-Z])/g, "Введите заглавную (большую) букву")
    .matches(/^(?=.*[0-9])/g, "Введите мин. 1 цифру")
    .oneOf([Yup.ref("password")], "Пароли не совпадают"),
});

// export const ProfilePersonalInfoSchema = Yup.object().shape({
//   name: Yup.string()
//     .required("Имя должно быть указано")
//     .min(2, "Укажите, пожалуйста, корректное имя")
//     .matches(
//       /^[a-zA-Zа-яА-ЯёЁіІїЇ][a-zA-Zа-яА-ЯёЁіІїЇ'-` ]+[a-zA-Zа-яА-ЯёЁіІїЇ]?$/i,
//       "Некорректное имя"
//     ),
//   surname: Yup.string()
//     .required("Фамилия должна быть указана")
//     .min(2, "Укажите, пожалуйста, корректное имя")
//     .matches(
//       /^[a-zA-Zа-яА-ЯёЁіІїЇ][a-zA-Zа-яА-ЯёЁіІїЇ'-` ]+[a-zA-Zа-яА-ЯёЁіІїЇ]?$/i,
//       "Некорректное фамилия"
//     ),
//   secondname: Yup.string()
//     .required("Отчество должно быть указано")
//     .min(2, "Укажите, пожалуйста, корректное имя")
//     .matches(
//       /^[a-zA-Zа-яА-ЯёЁіІїЇ][a-zA-Zа-яА-ЯёЁіІїЇ'-` ]+[a-zA-Zа-яА-ЯёЁіІїЇ]?$/i,
//       "Некорректное отчество"
//     ),
//   date: Yup.number().required("Дата должна быть указана"),
//   month: Yup.string().required("Месяц должен быть указан"),
//   year: Yup.number().required("Год должен быть указан"),
//   sex: Yup.string().required("Пол должен быть указан"),
// });
// export const ProfileContactsSchema = Yup.object().shape({
//   email: Yup.string().required("Email должен быть указано"),
//   phone: Yup.string()
//     .required("Номер телефона должен быть указана")
//     .max(18, "Некорректный номер телефона"),
// });

// export function depositSchema(min: number, max: number, currency: string) {
//   return Yup.object().shape({
//     amount: Yup.number()
//       .required("Сумма должна быть указана")
//       .min(min, `Сумма не должна быть меньше ${min} ${currency}`)
//       .max(max, `Сумма не должна быть больше ${max} ${currency}`),
//   });
// }
