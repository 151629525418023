import { UserService } from "./../../services/user/user.service";
import { currencies } from "../../constants/Constants";
import { TSelector } from "../../store/typing/types";
import { IGameSelector } from "./typing/game.interface";
import { RootState } from "../../store/rootStore";
import { createSelector } from "reselect";

const selectRawData = (state: RootState) => ({
  user: state.user,
  slots: state.slots, // Replace with the correct path to your slots state
});

// Create a memoized selector using reselect
export const GameSelector = createSelector(selectRawData, ({ user, slots }) => {
  const currentUser = new UserService(user);
  const currency = currentUser.getUserActiveCurrency();

  return {
    userId: user.id,
    currency,
    slots,
    isDemo: slots.isDemo,
  } as IGameSelector;
});
