import { useMemo } from "react";
import { createSelector } from "reselect";
import { RootState } from "../../../../store/rootStore";

// Select the raw data from the Redux state
const selectRawData = (state: RootState) => state.bonuses;

// Create a memoized selector using reselect
export const availableBonusesListSelector = createSelector(
  selectRawData,
  (bonuses) => ({ availableBonus: bonuses.available })
);
