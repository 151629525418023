import React, { useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";
import { IBonusesListItem } from "./typing/BonusesListItem.interface";
import "./BonusesListItem.css";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../store/hooks/store.hooks";
import { setRegistrationModal } from "../../../store/models/auth/auth.slice";
import { activateGlobalBonus } from "../../../store/models/bonuses/bonuses.thunk";
import { bonusesListItemSelector } from "../Bonuses.selector";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BonusShortRule } from "./BonusShortRules/bonus.short.rule";
import { BonusItem } from "./BonusItem/bonus.item";
import { BonusDialog } from "./BonusDialog/bonus.dialog";
import { BonusRules } from "./BonusRules/bonus.rules";
import { useMediaQuery } from "react-responsive";
import { Loader } from "../../loader/loader";

const BonusesListItem: React.FC<IBonusesListItem> = ({
  id,
  bg_img,
  sub_img,
  min_dep,
  wager_fs,
  wager_pers,
  fs,
  pers,
  duration,
  sub_type,
  title,
  sub_title,
  promo_code,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [infoMessage, setInfoMessage] = useState({ show: false, message: "" });
  const [infoMessageButton, setInfoMessageButton] = useState("");
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const navagate = useNavigate();
  const openInfo = () => {
    setInfo(true);
  };
  const closeInfo = () => {
    setInfo(false);
  };
  const [promocode, setPromocode] = useState<string | undefined>(promo_code);
  const { auth } = useAppSelector(bonusesListItemSelector);
  const promocodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromocode(e.target.value);
  };
  const dispatch = useAppDispatch();
  const rulesOpenHandler = () => {
    setRulesOpen(true);
  };
  const rulesCloseHandler = () => {
    setRulesOpen(false);
  };

  const playBonus = () => {
    navagate(`/lobby`);
  };

  const getBonus = async () => {
    if (!auth) {
      dispatch(setRegistrationModal(true));
      return;
    }
    if (promocode) {
      setLoading(true);

      const message: any = await dispatch(activateGlobalBonus({ promocode }));
      if (message.payload) {
        if (
          message.payload ===
          "Для получения бонуса, аккаунт необходимо верифицировать любым удобным для вас способом"
        ) {
          setInfoMessageButton(t("buttons.getverification"));
        } else if (message.payload === "Этот бонус был использован ранее") {
          setInfoMessageButton(t("buttons.close"));
        } else if (
          message.payload ===
          "Бонус был успешно активирован. Пополните игровой счет, чтобы получить его"
        ) {
          setInfoMessageButton(t("buttons.shorttopup"));
        } else if (message.payload === "Бонус был успешно активирован") {
          setInfoMessageButton(t("buttons.play"));
        } else if (
          message.payload ===
          "У вас есть другой активный бонус. Завершите его и после сможете принять участие в этом"
        ) {
          setInfoMessageButton(t("buttons.play"));
        } else if (message.payload === "Bonus not found") {
          setInfoMessageButton(t("buttons.close"));
        }
      } else {
        setInfoMessageButton(t("buttons.play"));
      }
      setLoading(false);
      setInfoMessage({
        show: true,
        message: message.payload,
      });
    } else {
      setInfoMessage({ show: true, message: "Бонус не найден" });
    }
  };
  const gameUrl = useAppSelector((state) => state.slots.games).filter(
    (game) => game.url === `${fs?.game}.game`
  )[0]?.url;

  const dialogHandler = () => {
    if (
      infoMessage.message ===
      "Для получения бонуса, аккаунт необходимо верифицировать любым удобным для вас способом"
    ) {
      navigate("/profile/personal-info/verification");
    } else if (
      infoMessage.message ===
      "Бонус был успешно активирован. Пополните игровой счет, чтобы получить его"
    ) {
      navigate("/profile/payments");
    } else if (infoMessage.message === "Бонус был успешно активирован") {
      navigate(gameUrl ? `/slots/${gameUrl}` : "/lobby");
    }
    setInfoMessage({ show: false, message: "" });
  };
  const closeDialog = () => setInfoMessage({ show: false, message: "" });
  useEffect(() => {}, [bg_img, sub_img]);
  const isMobile = useMediaQuery({ maxWidth: 600 });
  console.log("infoMessageButton", infoMessageButton);
  console.log("infoMessageButton:typeof ", Boolean(infoMessageButton));
  return (
    <React.Fragment>
      <Box className="bonus-card__container">
        <BonusItem
          bg_img={bg_img}
          sub_img={sub_img}
          sub_type={sub_type}
          promo_code={promo_code}
          title={title}
          sub_title={sub_title}
          fs={fs}
          pers={pers}
          openInfo={openInfo}
          promocodeHandler={promocodeHandler}
          getBonus={getBonus}
          active_bonus={Boolean(infoMessageButton)}
          playBonus={playBonus}
        />
        <BonusShortRule
          open={info}
          close={closeInfo}
          title={title}
          min_dep={min_dep}
          fs={fs}
          pers={pers}
          active_bonus={Boolean(infoMessageButton)}
          wager_fs={wager_fs}
          wager_pers={wager_pers}
          detailedHandler={rulesOpenHandler}
          getBonus={getBonus}
          playBonus={playBonus}
        />
      </Box>
      <BonusDialog
        infoMessage={infoMessage}
        loading={loading}
        infoMessageButton={infoMessageButton}
        closeDialog={closeDialog}
        dialogHandler={dialogHandler}
      />
      <BonusRules
        active_bonus={Boolean(infoMessageButton)}
        open={rulesOpen}
        close={rulesCloseHandler}
        title={title}
        sub_img={sub_img}
        min_dep={min_dep}
        fs={fs}
        duration={duration}
        pers={pers}
        wager_fs={wager_fs}
        wager_pers={wager_pers}
        activate={getBonus}
        playBonus={playBonus}
      />
      <Dialog
        open={loading}
        sx={{
          height: "100vh",
          overflowY: "auto",
          backgroundColor: "transparent",
          ".MuiDialog-container": {
            alignItems: "center",
            backdropFilter: "blur(5px)",
            position: "relative",
          },
          ".MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
        PaperProps={{
          sx: {
            margin: isMobile ? "106px 14px 0px 14px" : "0px 14px 0px 14px",
            width: "100%",
            maxWidth: "400px",
            height: isMobile ? "200px" : "300px",
            maxHeight: "100%",
            borderRadius: "var(--primary-border-radius)",
            overflow: "hidden",
            backgroundColor: "var(--secondary-background-color)",
          },
        }}
      >
        <Loader secondary />
      </Dialog>
    </React.Fragment>
  );
};

export default BonusesListItem;
