import React from "react";
import { Box } from "@mui/material";
import { Formik, Form, FormikErrors } from "formik";
import { ProfileContentContainer } from "../../ProfileContentContainer/ProfileContentContainer";
import { ProfileInput } from "../../../input/input";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../store/hooks/store.hooks";
import { ProfileSubmitButton } from "../../ProfileSubmitButton/ProfileSubmitButton";
// import { ProfileContactsSchema } from "../../../../utils/formValidations";
import { InputTel } from "../../../InputTel/inputTel";
import { addPhone } from "../../../../store/models/profile/profile.thunk";
import "./ProfileContacts.css";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const ProfileContacts = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state);
  const [loading, setLoading] = React.useState(false);
  const dispatch = useAppDispatch();

  const ProfileContactsSchema = Yup.object().shape({
    email: Yup.string().required(
      t("main.profile.contacts.validation.emailrequired")
    ),
    phone: Yup.string()
      .required(t("main.profile.contacts.validation.phonerequired"))
      .max(18, t("main.profile.contacts.validation.max")),
  });

  return (
    <ProfileContentContainer
      links={[
        { link: "/profile", text: "" },
        { link: "..", text: t("main.profile.links.personalinfo") },
        { link: "", text: t("main.profile.links.contacts") },
        {
          link: "../verification",
          text: t("main.profile.links.verification"),
        },
      ]}
    >
      <Box className="profile-contacts">
        <Formik
          initialValues={{
            email: user.contacts.email ? user.contacts.email : "",
            phone: user.contacts.phone ? user.contacts.phone : "",
          }}
          validationSchema={ProfileContactsSchema}
          onSubmit={async (values) => {
            setLoading(true);
            await dispatch(addPhone({ phone: values.phone }));
            setLoading(false);
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            isValid,
            setValues,
          }) => (
            <Form className="profile-contacts__form">
              <ProfileInput
                error={errors.email}
                touched={touched.email}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                name="email"
                label={t("main.profile.contacts.fields.email")}
                confirmed={user.contacts.emailConfirmation}
                disabled={user.contacts.email ? true : false}
              />
              <InputTel
                error={errors.phone}
                touched={touched.phone}
                onChange={(value: string) =>
                  setValues({ ...values, phone: value })
                }
                onBlur={handleBlur}
                value={values.phone}
                name="phone"
                label={t("main.profile.contacts.fields.phone")}
                disabled={user.contacts.phone ? true : false}
                confirmed={user.contacts.phoneConfirmation}
              />
              {(!user.contacts.email || !user.contacts.phone) && (
                <ProfileSubmitButton
                  text={t("buttons.save")}
                  disabled={loading && !isValid}
                  loading={loading}
                />
              )}
            </Form>
          )}
        </Formik>
      </Box>
    </ProfileContentContainer>
  );
};
