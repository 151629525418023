import React from "react";
import { Box, Typography } from "@mui/material";
import "./FooterNav.css";
import { useTranslation } from "react-i18next";
import { accordionData } from "../../../pages/Rules/Rules";
import { Link } from "react-router-dom";

export const FooterNav = () => {
  const { t } = useTranslation();

  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box className="footer-nav">
      <ul className="footer-nav__list">
        {accordionData.slice(0, 3).map(({ pathname, labelKey }) => (
          <Typography key={pathname} className="footer-nav__item">
            <Link
              to={pathname}
              className="footer-nav__item-link"
              onClick={handleLinkClick}
            >
              {t(labelKey)}
            </Link>
          </Typography>
        ))}
      </ul>
      <ul className="footer-nav__list">
        {accordionData.slice(3).map(({ pathname, labelKey }) => (
          <Typography key={pathname} className="footer-nav__item">
            <Link
              to={pathname}
              className="footer-nav__item-link"
              onClick={handleLinkClick}
            >
              {t(labelKey)}
            </Link>
          </Typography>
        ))}
      </ul>
    </Box>
  );
};
