import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import "./SlotFiltersCategory.css";
import recommended from "../../../assets/filters/recommended.png";
import news from "../../../assets/filters/news.png";
import fruits from "../../../assets/filters/fruits.png";
import animals from "../../../assets/filters/animals.png";
import books from "../../../assets/filters/books.png";
import seven from "../../../assets/filters/seven.png";
import buy_fs from "../../../assets/filters/buy_fs.png";
import popular from "../../../assets/filters/popular.png";
import all_slots from "../../../assets/filters/all_slots.png";
import { useAppDispatch } from "../../../store/hooks/store.hooks";
import { useNavigate } from "react-router-dom";
import {
  setAnimalsGames,
  setBooksGames,
  setBuyFSGames,
  setFruitsGames,
  setNewGames,
  setPopularGames,
  setRecommendedGames,
  setSevensGames,
  setSlotGames,
} from "../../../store/models/slots/slot.slice";

const filters = [
  {
    id: 0,
    name: "Рекомендуемые",
    image: recommended,
    action: "filterByRecommended",
  },
  {
    id: 7,
    name: "Популярные",
    image: popular,
    action: "filterByPopularity",
  },
  {
    id: 6,
    name: "Купи спины",
    image: buy_fs,
    action: "filterByBuyFS",
  },
  {
    id: 1,
    name: "Новинки",
    image: news,
    action: "filterByNew",
  },
  {
    id: 2,
    name: "Фрукты",
    image: fruits,
    action: "filterByFruits",
  },
  {
    id: 3,
    name: "Животные",
    image: animals,
    action: "filterByAnimals",
  },
  {
    id: 4,
    name: "Книги",
    image: books,
    action: "filterByBooks",
  },
  {
    id: 5,
    name: "777",
    image: seven,
    action: "filterBySevens",
  },
  {
    id: 8,
    name: "Все слоты",
    image: all_slots,
    action: "filterBySlots",
  },
];

type ISlotFiltersCategoryProps = {
  isMain?: boolean;
};

export const SlotFiltersCategory = ({ isMain }: ISlotFiltersCategoryProps) => {
  const [more, setMore] = useState(false);
  const dispatch = useAppDispatch();
  const navigator = useNavigate();
  const moreHandler = () => {
    setMore(!more);
  };
  const applyFilter = (action: string) => {
    if (action === "filterByPopularity" || action === "Популярные") {
      console.log("action", action);
      dispatch(setPopularGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByNew" || action === "Новинки") {
      console.log("action", action);
      dispatch(setNewGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByAnimals" || action === "Животные") {
      console.log("action", action);
      dispatch(setAnimalsGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByBooks" || action === "Книги") {
      console.log("action", action);
      dispatch(setBooksGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByBuyFS" || action === "Купи спины") {
      console.log("action", action);
      dispatch(setBuyFSGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByFruits" || action === "Фрукты") {
      console.log("action", action);
      dispatch(setFruitsGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterBySevens" || action === "777") {
      console.log("action", action);
      dispatch(setSevensGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterByRecommended" || action === "Рекомендуемые") {
      console.log("action", action);
      dispatch(setRecommendedGames());
      isMain && navigator("/lobby");
    }
    if (action === "filterBySlots" || action === "Все слоты") {
      console.log("action", action);
      dispatch(setSlotGames());
      isMain && navigator("/lobby");
    }
  };
  return (
    <Box className={`slot-category slot-filter-category ${more ? "more" : ""}`}>
      <Box className="slot-category__header">
        <Typography
          className="slot-category__heading"
          variant="subtitle1"
          component="h2"
        >
          Популярные категории
        </Typography>
      </Box>
      <Grid
        style={{
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {filters.map((filter) => {
          return (
            <FilterCard
              name={filter.name}
              image={filter.image}
              key={filter.id}
              onClick={() => {
                applyFilter(filter.action);
              }}
            />
          );
        })}
      </Grid>
      {!more && (
        <Box
          className="slot-category__footer"
          sx={{
            display: { xs: "flex", sm: "none" },
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Button
            className="Button Button-secondary primary-hover"
            sx={{ padding: "6px 16px", flex: "0 0 auto" }}
            onClick={moreHandler}
          >
            Больше
          </Button>
        </Box>
      )}
    </Box>
  );
};

type FilterCardProps = {
  name: string;
  image: string;
  onClick: () => void;
};

const FilterCard = ({ name, image, onClick }: FilterCardProps) => {
  return (
    <Box className="slot-filter-category__card" onClick={onClick}>
      <Box className="slot-filter-category__title">{name}</Box>
      <img className="slot-filter-category__image" src={image} alt={name} />
    </Box>
  );
};
