import { UserService } from "./../../services/user/user.service";
import { currencies } from "../../constants/Constants";
import { TSelector } from "../../store/typing/types";
import {
  IBonusesListSelector,
  IBonusesListItemSelector,
} from "./typing/BonusesList.interface";
import { RootState } from "../../store/rootStore";
import { createSelector } from "reselect";

// export const bonusesListSelector: TSelector<IBonusesListSelector> = ({
//   bonuses: { bonuses },
// }) => {
//   return {
//     bonuses,
//   };
// };

// export const bonusesListItemSelector: TSelector<IBonusesListItemSelector> = ({
//   user: {
//     verification,
//     contacts: { emailConfirmation, phoneConfirmation },
//   },
//   authorization: { auth },
// }) => {
//   return {
//     auth,
//     verification,
//     emailConfirmation,
//   };
// };

const selectBonuses = (state: RootState) => state.bonuses;
const selectUser = (state: RootState) => state.user;
const selectAuthorization = (state: RootState) => state.authorization;

export const bonusesListSelector: TSelector<IBonusesListSelector> =
  createSelector(
    selectBonuses,
    ({ bonuses }) =>
      ({
        bonuses: bonuses.filter((item) => {
          return (
            item.type === "all" &&
            (item.placement === "banner" || item.placement === "none")
          );
        }),
      } as IBonusesListSelector)
  );

export const bonusesListItemSelector: TSelector<IBonusesListItemSelector> =
  createSelector(
    selectUser,
    selectAuthorization,
    (
      { verification, contacts: { emailConfirmation, phoneConfirmation } },
      { auth }
    ) =>
      ({
        auth,
        verification,
        emailConfirmation,
      } as IBonusesListItemSelector)
  );
