import { PayloadAction } from "@reduxjs/toolkit";
import { BonusesAction } from "./typing/bonuses.enum";
import {
  IActiveBonus,
  IAddBonusPayload,
  IBonus,
  IBonuses,
} from "./typing/bonuses.interface";

const reducers = {
  [BonusesAction.ADD_BONUSES]: (
    state: IBonuses,
    action: PayloadAction<IBonus[]>
  ) => {
    const newArray: IBonus[] = [];
    action.payload.forEach((bonus: IBonus) => {
      const elem = state.bonuses.find((stateBonus: IBonus) => {
        return stateBonus.id === bonus.id;
      });
      if (elem) {
        return;
      }
      newArray.push(bonus);
    });
    state.bonuses = [...state.bonuses, ...newArray];
    return state;
  },
  [BonusesAction.ADD_MY_ACTIVATED_BONUSES]: (
    state: IBonuses,
    action: PayloadAction<IActiveBonus[]>
  ) => {
    const newArray: IActiveBonus[] = [];
    action.payload.forEach((bonus: IActiveBonus) => {
      const elem = state.activatedBonuses.find((stateBonus: IActiveBonus) => {
        return stateBonus.id === bonus.id;
      });
      if (elem) {
        return;
      }
      newArray.push(bonus);
    });
    state.activatedBonuses = [...state.activatedBonuses, ...newArray];
    return state;
  },
  [BonusesAction.ADD_AVAILABLE_BONUSES]: (
    state: IBonuses,
    action: PayloadAction<IBonus[]>
  ) => {
    const newArray: IBonus[] = [];
    action.payload.forEach((bonus: IBonus) => {
      const elem = state.available.find((stateBonus: IBonus) => {
        return stateBonus.id === bonus.id;
      });
      if (elem) {
        return;
      }
      newArray.push(bonus);
    });
    state.available = [...state.available, ...newArray];
    return state;
  },
  [BonusesAction.ADD_BONUS_BG_IMAGE]: (
    state: IBonuses,
    action: PayloadAction<IAddBonusPayload>
  ) => {
    const copy = JSON.parse(JSON.stringify(state));
    const bonus = copy.bonuses.filter(
      (b: IBonus) => b.id === action.payload.id
    );
    if (!bonus.length) {
      return;
    }
    if (action.payload.mobile) {
      bonus[0].images.mobile.bg_image.src = action.payload.src;
      return copy;
    } else {
      bonus[0].images.desktop.bg_image.src = action.payload.src;
      return copy;
    }
  },
  [BonusesAction.ADD_BONUS_SUB_IMAGE]: (
    state: IBonuses,
    action: PayloadAction<IAddBonusPayload>
  ) => {
    const copy = JSON.parse(JSON.stringify(state));
    const bonus = copy.bonuses.filter(
      (b: IBonus) => b.id === action.payload.id
    );
    if (!bonus.length) {
      return;
    }
    if (action.payload.mobile) {
      bonus[0].images.mobile.sub_image.src = action.payload.src;
      return copy;
    } else {
      bonus[0].images.desktop.sub_image.src = action.payload.src;
      return copy;
    }
  },
  [BonusesAction.ADD_MY_ACTIVATED_BONUS_SUB_IMAGE]: (
    state: IBonuses,
    action: PayloadAction<IAddBonusPayload>
  ) => {
    const copy = JSON.parse(JSON.stringify(state)) as IBonuses;
    const bonus = copy.activatedBonuses.filter(
      (b: IActiveBonus) => b.id === action.payload.id
    );
    if (!bonus.length) {
      return;
    }
    if (action.payload.mobile) {
      bonus[0].bonus.images.mobile.sub_image.src = action.payload.src;
    } else {
      bonus[0].bonus.images.desktop.sub_image.src = action.payload.src;
    }
    return copy;
  },
  [BonusesAction.ADD_AVAILABLE_BONUS_BG_IMAGE]: (
    state: IBonuses,
    action: PayloadAction<IAddBonusPayload>
  ) => {
    const copy = JSON.parse(JSON.stringify(state));
    const bonus = copy.available.filter(
      (b: IBonus) => b.id === action.payload.id
    );
    if (!bonus.length) {
      return;
    }
    if (action.payload.mobile) {
      bonus[0].images.mobile.bg_image.src = action.payload.src;
      return copy;
    } else {
      bonus[0].images.desktop.bg_image.src = action.payload.src;
      return copy;
    }
  },
  [BonusesAction.ADD_AVAILABLE_BONUS_SUB_IMAGE]: (
    state: IBonuses,
    action: PayloadAction<IAddBonusPayload>
  ) => {
    const copy = JSON.parse(JSON.stringify(state));
    const bonus = copy.available.filter(
      (b: IBonus) => b.id === action.payload.id
    );
    if (!bonus.length) {
      return;
    }
    if (action.payload.mobile) {
      bonus[0].images.mobile.sub_image.src = action.payload.src;
      return copy;
    } else {
      bonus[0].images.desktop.sub_image.src = action.payload.src;
      return copy;
    }
  },
};

export default reducers;
